.c-title {
  &--top {
    color: #373737;
    font-size: 6.5rem;
    font-weight: bold;
    font-family: $nobel;
    letter-spacing: 0.1em;
    @include header {
      font-size: 5rem;
    }
    @include sp {
      font-size: 3rem;
    }
  }
  &--small {
    color: #373737;
    font-size: 5rem;
    font-weight: bold;
    font-family: $nobel;
    letter-spacing: 0.1em;
    @include header {
      font-size: 5rem;
    }
    @include sp {
      font-size: 3rem;
    }
  }
  &_parent {
    position: absolute;
    bottom: 0;
    z-index: 3;
    @include sp {
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &_image {
    max-width: 80vw;
    width: 80rem;
    bottom: 0%;
    z-index: 3;
    &--green {
      position: relative;
    }
    &--white {
      @include pc {
        left: -128%;
      }
      position: absolute;
    }
  }
  &_white {
    position: relative;
    overflow: hidden;
    height: 50%;
    position: absolute;
    left: 56%;
    bottom: 0;
    width: 35rem;
    @media screen and (min-width: 768px) and (max-width: 1000px) {
      width: 35vw;
    }
    @include sp {
      width: 100%;
      left: 0;
    }
  }
}
