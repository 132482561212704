.c-anime {
  &_fadeInLeft {
    position: relative;
    overflow: hidden;
    // background ver.
    // width: calc(100% - 350px);
    // margin-left: auto;
    // height: 100%;
    &.js-anime.active .c-anime_image {
      opacity: 1;
      animation: appearance-img 1.6s cubic-bezier(0.8, 0, 0.17, 1);
    }
    &.js-anime-load.active .c-anime_image {
      opacity: 1;
      animation: appearance-img 1.6s cubic-bezier(0.8, 0, 0.17, 1);
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: #008f3d;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &.js-anime.active {
      &::before {
        animation: fadeInLeft-bg 1.6s cubic-bezier(0.8, 0, 0.17, 1) forwards;
      }
    }
    &.js-anime-load.active {
      &::before {
        animation: fadeInLeft-bg 1.6s cubic-bezier(0.8, 0, 0.17, 1) forwards;
      }
    }
  }
  &_fadeInBottom {
    overflow: hidden;
    &.js-anime.active .c-anime_text {
      animation: fadeInBottom-text 1.6s cubic-bezier(0.8, 0, 0.17, 1) forwards;
    }
  }
  &_test {
    // background-image: url('../img/work_hero.png');
    // background-size: cover;
    // background-repeat: no-repeat;
    // width: 100%;
    // height: 100%;
    //  background-position: 50%;
  }
  &_image {
    // background ver.
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // right: 0;
    // top: 70px;
  }
  &_slide {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 600px;
    @include over {
      height: 900px;
    }
    @include sp {
      height: 100%;
    }
    &Item {
      z-index: 1;
      opacity: 0;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 0;
      animation: slideAnime 24s 0s infinite;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0.6;
        background: #000;
        mix-blend-mode: multiply;
      }
      &:nth-of-type(2) {
        animation-delay: 6s;
      }
      &:nth-of-type(3) {
        animation-delay: 12s;
      }
      &:nth-of-type(4) {
        animation-delay: 18s;
      }
      &--first {
        @include pc {
          background-image: url('../img/top_hero_01.png');
        }
        @include sp {
          background-image: url('../img/top_hero_01_sp.png');
        }
      }
      &--second {
        background-image: url('../img/top_hero_02.png');
        @include sp {
          background-image: url('../img/top_hero_02_sp.png');
        }
      }
      &--third {
        background-image: url('../img/top_hero_03.png');
        @include sp {
          background-image: url('../img/top_hero_03_sp.png');
        }
      }
      &--forth {
        background-image: url('../img/top_hero_04.png');
        @include sp {
          background-image: url('../img/top_hero_04_sp.png');
        }
      }
    }
  }
}

@keyframes slideAnime {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale(1.2);
    z-index: 2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes appearance-img {
  0% {
    opacity: 0;
  }
  40%,
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appearance-img02 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInLeft-bg {
  0% {
    width: 0%;
    left: 0%;
  }
  40%,
  60% {
    width: 100%;
    left: 0%;
  }
  100% {
    width: 100%;
    left: 101%;
  }
}

@keyframes fadeInBottom-text {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  40%,
  60% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes extendLeft-bg {
  0% {
    width: 0%;
  }
  40%,
  60% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes shrinkLeft-bg {
  0% {
    opacity: 0;
    width: 100%;
  }
  40% {
    opacity: 0;
    width: 100%;
  }
  60% {
    opacity: 1;
    width: 100%;
  }
  80% {
    opacity: 1;
    width: 100%;
  }
  100% {
    opacity: 1;
    width: 0%;
  }
}
