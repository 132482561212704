@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab($minWidth: 768px, $maxWidth: 900px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin tab02($width: 900px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin over($width: 1500px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin header($minWidth: 768px, $maxWidth: 1100px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin topGreen1($minWidth: 901px, $maxWidth: 1020px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin topGreen2($minWidth: 801px, $maxWidth: 900px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin topGreen3($minWidth: 768px, $maxWidth: 900px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin womenGraph($minWidth: 768px, $maxWidth: 1000px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin workBox1($minWidth: 1201px, $maxWidth: 1300px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin workBox2($minWidth: 1101px, $maxWidth: 1200px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin workBox3($minWidth: 768px, $maxWidth: 1100px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin sectionTitle($minWidth: 768px, $maxWidth: 1366px) {
  @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin sectionImage($width: 1367px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin buttonHover($color) {
  // color: #fff;
  // letter-spacing: 0.1em;
  // background-color: $color;
  @include pc {
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  &:hover {
    @include pc {
      // padding: 2rem;
      border: 1px $color solid;
      color: $color;
      background: #fff;
    }
    &::after {
      @include pc {
        border-bottom: 1px solid $color;
        border-right: 2px solid $color;
      }
    }
  }
}

@mixin workCard($bgColor, $shapeColor) {
  background-color: $bgColor;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6rem 6rem 0 0;
    border-color: $shapeColor transparent transparent transparent;
    @include sp {
      border-width: 3rem 3rem 0 0;
    }
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 6rem 6rem;
    border-color: transparent transparent $shapeColor transparent;
    @include sp {
      border-width: 0 0 3rem 3rem;
    }
  }
}

@mixin breadcrumb($color) {
  color: #fff;
  font-weight: bold;
  font-size: 1.8rem;
  letter-spacing: 0.04em;
  background-color: $color;
  padding: 2.1rem;
  max-width: 24rem;
  width: 28vw;
  text-align: center;
  position: relative;
  @include sp {
    font-size: 1.4rem;
    padding: 1.05rem;
    max-width: 8rem;
  }
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 30px 0 30px 20px;
    border-color: transparent transparent transparent $color;
    @include sp {
      right: -4.4vw;
      border-width: 5.5vw 0 5.5vw 4.5vw;
    }
  }
}

@mixin breadcrumb_career($color) {
  background-color: $color;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    right: -30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-color: transparent transparent transparent $color;
    border-style: solid;
    border-width: 4rem 0 4rem 3rem;
    z-index: 1;
    @include header {
      border-width: 3.8rem 0 3.8rem 3rem;
    }
    @include sp {
      right: -15px;
      border-width: 2rem 0 2rem 1.5rem;
    }
  }
}
