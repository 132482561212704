.p-work {
  &Women {
    &_hero {
      padding: 7rem 0 12rem;
    }
    &About {
      padding: 6rem 0 7rem;
      @include sp {
        padding: 3.5rem 0;
      }
      &_text {
        text-align: center;
        @include sp {
          text-align: left;
        }
      }
    }
    &Workstyle {
      background-color: #fff;
      &_wrap {
        padding: 5rem 0 12rem;
        @include sp {
          padding: 3.5rem 0;
        }
      }
      &_image {
        max-width: 38rem;
        width: 100%;
      }
      &_item {
        flex: 1;
        margin-left: 5rem;
        @include sp {
          margin-left: 0rem;
        }
      }
      &_subTitle {
        letter-spacing: 0.04em;
        color: #008f3d;
        font-size: 2rem;
        font-weight: 700;
        border-bottom: 1px solid #008f3d;
        padding: 0 0 2rem;
        margin: 0 0 2rem;
        @include sp {
          font-size: 1.8rem;
          margin: 2rem 0;
          padding: 0 0 1rem;
        }
      }
      &_small {
        font-size: 1.4rem;
        font-weight: 500;
        color: #008f3d;
        letter-spacing: 0.04em;
        text-align: right;
        margin-bottom: 1.5rem;
      }
      &_name {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        text-align: right;
        @include sp {
          font-size: 1.6rem;
        }
      }
      &_text {
        margin-bottom: 1.5rem;
      }
      &_container {
        & + & {
          margin-top: 9rem;
          @include sp {
            margin-top: 4rem;
          }
        }
      }
    }
    &Support {
      background-color: #fff;
      &_wrap {
        padding: 6rem 0 10rem;
        @include sp {
          padding: 3.5rem 0;
        }
      }
      &_container {
        display: flex;
        justify-content: space-between;
        @include sp {
          display: block;
        }
      }
      &_image {
        max-width: 32rem;
        width: 100%;
        margin: 0 0 2rem;
        @include sp {
          width: 60%;
          max-width: 26rem;
          margin: 0 auto 1rem;
        }
        &--single {
          margin-bottom: 3rem;
        }
      }
      &_name {
        font-size: 2.2rem;
        font-weight: bold;
        color: #008f3d;
        text-align: center;
        margin-bottom: 2rem;
        letter-spacing: 0.04em;
        line-height: calc(32 / 22);
        @include womenGraph {
          font-size: 2vw;
        }
        @include sp {
          font-size: 1.6rem;
          margin-bottom: 1rem;
        }
      }
      &_small {
        font-size: 1.4rem;
        color: #008f3d;
        letter-spacing: 0.04em;
        text-align: center;
        line-height: 1.2;
      }
      &_item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include sp {
          &:not(:last-child) {
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
}
