.p-contact {
  &_hero {
    &Title {
      @include header {
        max-width: 29rem;
      }
    }
    &Text {
      @include header {
        max-width: 29rem;
      }
    }
  }
  // start form
  &_notice {
    font-size: 1.4rem;
    color: #b70000;
    margin: 0.5rem 0 0;
    @include sp {
      font-size: 1.2rem;
    }
  }
  &_form {
    padding: 7rem 0 9rem;
    @include sp {
      padding: 3.5rem 0 4.5rem;
    }
  }
  &_table {
    width: 100%;
    border-top: 1px solid #646464;
    border-bottom: 1px solid #646464;
    margin-bottom: 5rem;
  }
  &_question {
    padding: 3rem 0;
    max-width: 35%;
    width: 100%;
    @include sp {
      max-width: 100%;
      padding: 1.5rem 0 0 0;
    }
    &--unset {
      padding: 5rem 0 3rem;
      @include sp {
        padding: 1.5rem 0 0 0;
      }
    }
  }
  &_answer {
    padding: 3rem 0rem;
    flex: 1;
    @include sp {
      padding: 1.5rem 0rem;
    }
  }
  &_wrap {
    display: flex;
    align-items: center;
    @include sp {
      display: block;
    }
    &--unset {
      // TODO: フォーム導入時に、確認画面でalign-items: center;に変更
      align-items: unset;
    }
    & + & {
      border-top: 1px solid #646464;
    }
  }
  &_require {
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    color: #fff;
    background-color: #e3aa29;
    padding: 0.5rem 1rem;
    @include sp {
      font-size: 1.2rem;
    }
    &--hidden {
      opacity: 0;
      @include sp {
        display: none;
      }
    }
  }
  &_container {
    display: flex;
    align-items: center;
  }
  &_buttons {
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
    }
  }
  &_pseudo {
    position: relative;
    text-indent: 3rem;
    display: block;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
    }
    &::before {
      background-color: #fff;
      border: 2px solid #ddd;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      left: 0;
    }
    &::after {
      background-color: #008f3d;
      border-radius: 50%;
      opacity: 0;
      width: 18px;
      height: 18px;
      left: 3px;
    }
  }
  &_field {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    margin-left: 4.5rem;
    @include sp {
      font-size: 1.4rem;
      margin-left: 1.5rem;
    }
  }
  &_file {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    & + & {
      margin-top: 2rem;
    }
    @include sp {
      display: block;
    }
  }
  &_upload {
    display: flex;
    align-items: center;
    flex: 1;
    @include sp {
      display: block;
    }
  }
  &_document {
    font-size: 1.4rem;
    color: #008f3d;
    letter-spacing: 0.04em;
    width: 16%;
    @include sp {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  &_collect {
    margin-left: 3%;
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    @include sp {
      margin-left: 0;
    }
  }
  &_agree {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    position: relative;
    @include sp {
      margin-left: 2rem;
      font-size: 1.4rem;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
    }
    &::before {
      background-color: #fff;
      border: 1px solid #000;
      width: 20px;
      height: 20px;
      left: -10%;
      @include sp {
        left: -25%;
      }
    }
    &::after {
      content: '';
      width: 18px;
      height: 9px;
      margin-top: -9px;
      top: 50%;
      left: -9%;
      transform: rotate(-45deg);
      border-bottom: 3px solid;
      border-left: 3px solid;
      border-color: #585753;
      opacity: 0;
      @include sp {
        left: -22%;
      }
    }
  }
  &Input {
    &_button {
      appearance: none;
      border: none;
      max-width: 36rem;
      width: 100%;
      padding: 2rem;
      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: 0.04em;
      color: #fff;
      display: block;
      cursor: pointer;
      text-align: center;
      @include sp {
        font-size: 1.6rem;
        padding: 1rem;
        max-width: 24rem;
        margin: 0 auto;
        & + & {
          margin-top: 2rem;
        }
      }
      &--submit {
        position: relative;
        background-color: #008f3d;
        border: 1px solid transparent;
        @include buttonHover(#008f3d);
        &::after {
          content: '';
          position: absolute;
          width: 3rem;
          height: 0.8rem;
          border-bottom: 1px solid #fff;
          border-right: 2px solid #fff;
          transform: translateY(-50%) skew(45deg);
          right: 10%;
          top: 50%;
          @include sp {
            width: 1.5rem;
            height: 0.4rem;
          }
        }
      }
      &--back {
        border: 1px solid transparent;
        @include buttonHover(#bebebe);
        background-color: #bebebe;
      }
    }
    &_text {
      border-radius: 0.5rem;
      border: none;
      width: 100%;
      font-size: 1.6rem;
      padding: 1.2rem 2rem;
      line-height: 2;
      font-weight: bold;
      @include sp {
        font-size: 1.4rem;
        padding: 0.8rem;
      }
      &::placeholder {
        color: #bebebe;
      }
      & + & {
        margin-top: 2rem;
      }
    }
    &_file {
      display: none;
    }
    &_radio {
      display: none;
      &:checked ~ .p-contact_pseudo::after {
        opacity: 1;
      }
    }
    &_check {
      display: none;
      &:checked ~ .p-contact_agree::after {
        opacity: 1;
      }
    }
    &_textarea {
      border-radius: 0.5rem;
      border: none;
      width: 100%;
      height: 20rem;
      font-size: 1.6rem;
      padding: 1.6rem;
      line-height: 2;
      resize: vertical;
      @include sp {
        font-size: 1.4rem;
        padding: 0.8rem;
        height: 10rem;
      }
      &::placeholder {
        color: #bebebe;
      }
    }
    &_label {
      display: block;
      width: calc(100% / 3);
      margin: 1rem 0rem;
      @include sp {
        width: calc(100% / 2);
        margin: 1.5rem 0rem;
      }
    }
    &_container {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &Terms {
    &_title {
      font-size: 2rem;
      font-weight: bold;
      color: #008f3d;
      letter-spacing: 0.04em;
      text-align: center;
      margin-bottom: 2rem;
      @include sp {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }
    }
    &_wrap {
      background-color: #fff;
      padding: 4rem;
      margin-bottom: 7rem;
      @include sp {
        padding: 2rem;
        margin-bottom: 3.5rem;
      }
    }
    &_box {
      padding: 1rem;
      margin-bottom: 3rem;
      height: 16rem;
      background-color: #f0f0f0;
    }
    &_text {
      font-size: 1.4rem;
      letter-spacing: 0.04em;
      line-height: 2;
      overflow-y: scroll;
      height: 100%;
      @include sp {
        font-size: 1.2rem;
      }
    }
    &_label {
      display: block;
      text-align: center;
    }
  }
  &_label {
    background-color: #d1d1d8;
    border-radius: 10rem;
    padding: 0.7rem 1.4rem;
    display: inline-block;
    cursor: pointer;
    filter: drop-shadow(1px 3px 2px #b7b7b7);
    @include pc {
      transition: filter ease 0.1s, transform ease 0.1s;
    }
    @include sp {
      margin-bottom: 1rem;
    }
    &:hover {
      @include pc {
        filter: none;
        position: relative;
        transform: translateY(1px);
      }
    }
    &Text {
      font-size: 1.4rem;
      letter-spacing: 0.04em;
    }
  }
  &_asterisk {
    display: block;
    font-size: 1.2rem;
    letter-spacing: 0.04em;
    line-height: 1.5;
    @include sp {
      font-size: 1rem;
    }
  }
  &Thanks {
    &_title {
      font-size: 2.4rem;
      color: #008f3d;
      letter-spacing: 0.04em;
      text-align: center;
      margin-bottom: 4rem;
      line-height: 1.5;
      @include sp {
        font-size: 1.7rem;
        margin-bottom: 2rem;
      }
    }
    &_text {
      font-size: 1.6rem;
      letter-spacing: 0.04em;
      line-height: 2;
      text-align: center;
      @include sp {
        font-size: 1.4rem;
      }
    }
  }
  // end form
  &_breadcrumb {
    padding: 7rem 0rem;
    @include sp {
      padding: 3.5rem 0rem;
    }
  }
  &_about {
    background-color: #fff;
    padding: 7rem 0rem;
    @include sp {
      padding: 3.5rem 0rem;
    }
  }
  &_text {
    font-size: 1.6rem;
    letter-spacing: 0.04em;
    line-height: 2;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_title {
    font-family: $nobel;
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 1.5rem;
    @include sp {
      font-size: 3rem;
      margin-bottom: 0.8rem;
    }
  }
  &_small {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-align: center;
    color: #008f3d;
    margin-bottom: 4rem;
    @include sp {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }
  &_subTitle {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    color: #008f3d;
    margin-bottom: 4rem;
    @include sp {
      font-size: 1.8rem;
      margin-bottom: 2rem;
      line-height: 1.5;
    }
  }
  &_box {
    display: flex;
    justify-content: space-between;
    @include pc {
      max-width: 910px;
      margin: 0 auto;
    }
    @include sp {
      display: block;
    }
  }
  &_button {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    font-family: $oswald;
    padding: 2rem;
    max-width: 42rem;
    width: 100%;
    text-align: center;
    position: relative;
    display: block;
    border: 1px solid transparent;
    @include sp {
      padding: 1rem;
      font-size: 1.4rem;
      max-width: 27rem;
      margin: 0 auto;
      & + & {
        margin-top: 1rem;
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 0.8rem;
      border-bottom: 1px solid #fff;
      border-right: 2px solid #fff;
      transform: translateY(-50%) skew(45deg);
      right: 10%;
      top: 50%;
      @include sp {
        width: 1.5rem;
        height: 0.4rem;
      }
    }
    &--blue {
      color: #fff;
      background-color: #61959f;
      @include buttonHover(#61959f);
      &:hover {
        @include pc {
          padding: 2rem;
        }
      }
    }
    &--yellow {
      color: #fff;
      background-color: #e3aa29;
      @include buttonHover(#e3aa29);
      &:hover {
        @include pc {
          padding: 2rem;
        }
      }
    }
  }
  &Top {
    &_background {
      background: url('../img/contact_background.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 10rem 5rem;
      position: relative;
      @include sp {
        padding: 5rem 0rem;
      }
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0.5;
        background: #000;
        mix-blend-mode: multiply;
      }
    }
    &_box {
      max-width: 90rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    &_button {
      font-size: 3.6rem;
      font-family: $oswald;
      padding: 2rem;
      letter-spacing: 0.1em;
      max-width: 42rem;
      width: 100%;
      text-align: center;
      position: relative;
      display: block;
      border: 1px solid transparent;
      &::after {
        content: '';
        position: absolute;
        width: 3rem;
        height: 0.8rem;
        border-bottom: 1px solid #fff;
        border-right: 2px solid #fff;
        transform: translateY(-50%) skew(45deg);
        right: 10%;
        top: 50%;
        @include sp {
          width: 1.5rem;
          height: 0.4rem;
        }
      }
      @include sp {
        padding: 1rem;
        font-size: 1.8rem;
        max-width: 14rem;
      }
      &--grey {
        color: #fff;
        background-color: #bebebe;
        @include buttonHover(#bebebe);
        &:hover {
          @include pc {
            padding: 2rem;
          }
        }
      }
      &--yellow {
        color: #fff;
        background-color: #e3aa29;
        @include buttonHover(#e3aa29);
        &:hover {
          @include pc {
            padding: 2rem;
          }
        }
      }
    }
  }
}
