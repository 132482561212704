// 利用規約チェックボックスの位置
#agree-wrap {
  display: block !important;
  text-align: center !important;
}

// 利用規約の同意チェック
#agree-1 + .mwform-checkbox-field-text {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.04em;
  position: relative;
  @include sp {
    margin-left: 2rem;
    font-size: 1.2rem;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }
  &::before {
    background-color: #fff;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
    left: -10%;
    @include sp {
      left: -15%;
    }
  }
  &::after {
    content: '';
    width: 18px;
    height: 9px;
    margin-top: -9px;
    top: 50%;
    left: -9%;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: #585753;
    opacity: 0;
    @include sp {
      left: -13%;
    }
  }
}

// チェックボックスのチェックマーク表示
#agree-1:checked ~ .mwform-checkbox-field-text::after {
  opacity: 1;
}

// 希望職種
#jobs-1 + .mwform-checkbox-field-text,
#jobs-2 + .mwform-checkbox-field-text,
#jobs-3 + .mwform-checkbox-field-text,
#jobs-4 + .mwform-checkbox-field-text,
#jobs-5 + .mwform-checkbox-field-text {
  font-size: 1.6rem;
  position: relative;
  display: block;
  text-indent: 3rem;
  &::before {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    display: block;
    background-color: #fff;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
  }
  &::after {
    content: '';
    width: 18px;
    height: 9px;
    margin-top: -9px;
    position: absolute;
    top: 80%;
    left: 0;
    transform: translateY(-50%) rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: #008f3d;
    opacity: 0;
    display: block;
  }
}

// チェックボックスのチェックマーク表示
#jobs-1:checked ~ .mwform-checkbox-field-text::after,
#jobs-2:checked ~ .mwform-checkbox-field-text::after,
#jobs-3:checked ~ .mwform-checkbox-field-text::after,
#jobs-4:checked ~ .mwform-checkbox-field-text::after,
#jobs-5:checked ~ .mwform-checkbox-field-text::after {
  opacity: 1;
}

#job-select > .mwform-checkbox-field.horizontal-item {
  display: block;
  width: calc(100% / 3);
  margin: 1rem 0rem;
  @include sp {
    width: calc(100% / 2);
    margin: 1.5rem 0rem;
  }
}

// デフォルトのfileを非表示
.mw_wp_form {
  & input[type='file'] {
    display: none !important;
  }
}

// fileの削除ボタン
.mwform-file-delete {
  display: none;
}

// radioボタン
.mwform-radio-field-text {
  position: relative;
  text-indent: 3rem;
  display: block;
  font-size: 1.6rem;
  @include sp {
    font-size: 1.4rem;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }
  &::before {
    background-color: #fff;
    border: 2px solid #ddd;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    left: 0;
  }
  &::after {
    background-color: #008f3d;
    border-radius: 50%;
    opacity: 0;
    width: 18px;
    height: 18px;
    left: 3px;
  }
}

// ラジオボタンのサイズ調整
.mwform-radio-field.horizontal-item {
  display: block;
  width: calc(100% / 3);
  margin: 1rem 0rem;
  @include sp {
    width: calc(100% / 2);
    margin: 1.5rem 0rem;
  }
}

// ラジオボタンの位置調整
.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0px !important;
}
// ラジオボタンのマーク表示
.p-contactInput_radio {
  display: none;
  &:checked ~ .mwform-radio-field-text::after {
    opacity: 1;
  }
}

// 入力画面の見た目調整
.mw_wp_form_input {
  .p-contact_breadcrumb.input {
    display: block;
  }
  .p-contact_breadcrumb.confirm {
    display: none;
  }
}

// 確認画面の見た目調整
.mw_wp_form_confirm {
  .p-contact_breadcrumb.input {
    display: none;
  }
  .p-contact_breadcrumb.confirm {
    display: block;
  }
  .p-contact_wrap--unset {
    padding: 0 !important;
  }
  .p-contact_question {
    display: flex;
    align-items: center;
    &--unset {
      padding: 3rem 0 !important;
      @include sp {
        padding: 1.5rem 0 0 0 !important;
      }
    }
  }
  .p-contact_answer {
    font-size: 1.6rem;
    letter-spacing: 0.04em;
    line-height: 2;
  }
  .p-contact_notice {
    display: none;
  }
  .p-contact_wrap {
    &:nth-child(8) {
      display: none;
    }
    &:nth-child(9) {
      & .p-contact_field {
        @include sp {
          margin-left: 0rem;
        }
      }
    }
  }
  .p-contactTerms_wrap {
    display: none;
  }
}

.mw_wp_form .error {
  margin: 1rem 0 !important;
  font-size: 1.4rem !important;
  letter-spacing: 0.04em !important;
  @include sp {
    font-size: 1.2rem !important;
    margin: 0.5em 0 !important;
  }
}

.mw_wp_form .p-contactTerms_wrap .error {
  text-align: center !important;
}

.mwform-checkbox-field-text {
  cursor: pointer;
  line-height: 1.5;
}
