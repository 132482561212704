.p-hero {
  padding-bottom: 8rem;
  @include sp {
    padding-bottom: 5.5rem;
  }
  &_name {
    max-width: 80vw;
    width: 80rem;
    @include sp {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &_container {
    display: flex;
    justify-content: space-between;
    margin-top: 9rem;
    @include tab {
      margin-top: 3rem;
    }
    @include sp {
      margin-top: 3rem;
      display: block;
      position: relative;
    }
    &::before {
      @include sp {
        content: '';
        position: absolute;
        display: block;
        width: 110%;
        height: 102%;
        background: #008f3d;
        z-index: 0;
        top: 3%;
        left: 0%;
      }
    }
  }
  &_item {
    position: relative;
    z-index: 3;
    &--margin {
      margin-top: 7rem;
    }
    @include pc {
      flex-basis: 48%;
    }
  }
  &_box {
    @include sp {
      padding: 1rem;
    }
  }
  &_background {
    &::before {
      @include pc {
        content: '';
        position: absolute;
        display: block;
        width: 9999px;
        height: 500%;
        background: #008f3d;
        z-index: 0;
        top: 100%;
        left: 56%;
      }
      @include topGreen1 {
        height: calc(500% - (100vw - 1060px));
      }
      @include topGreen2 {
        height: calc(500% - (100vw - 950px));
      }
      @include topGreen3 {
        height: calc(500% - (100vw - 980px));
      }
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      background: #008f3d;
      z-index: -1;
      bottom: 0%;
      @include pc {
        width: 9999px;
        height: 50%;
        left: 56%;
      }
      @include sp {
        width: 200%;
        height: 65%;
        bottom: -10px;
        margin-left: -50%;
      }
    }
  }
  &_title {
    position: absolute;
    top: 40%;
    z-index: 10;
    transform: translateY(-40%);
    @include sp {
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
    &Image {
      margin-bottom: 3rem;
      @include sp {
        margin-bottom: 1.5rem;
      }
    }
    &Text {
      font-size: 1.4rem;
      color: #fff;
      font-weight: bold;
      animation-name: text;
      animation-delay: 4s;
      animation-iteration-count: 1;
      animation-duration: 1.6s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      line-height: 1.6;

      @include sp {
        line-height: 1.6;
        text-align: center;
        font-size: 1.2rem;
        letter-spacing: 0.1em;
        width: 90%;
        margin: 0 auto;
      }
    }
    &Svg {
      max-width: 57rem;
      width: 100%;
      animation-name: text;
      animation-delay: 3s;
      animation-iteration-count: 1;
      animation-duration: 0.8s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      @include sp {
        max-width: 25rem;
        margin: 0 auto;
      }
    }
  }
  &_subTitle {
    font-size: 3rem;
    color: #fff;
    line-height: 1.3;
    font-weight: bold;
    letter-spacing: 0.04em;
    @include sp {
      font-size: 1.7rem;
    }
  }
  &_text {
    color: #fff;
  }
  &_highlight {
    font-size: 2rem;
    color: #008f3d;
    background: #fff;
    display: inline-block;
    padding: 0.5rem 2rem;
    font-weight: bold;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_image {
    position: relative;
    @include sp {
      width: 100vw;
      height: 88.2vh;
    }
  }
}
