.p-company {
  &Outline {
    &_table {
      padding: 6rem 0 7rem;
      @include sp {
        padding: 3.5rem 0;
      }
    }
    &_multiple {
      @include pc {
        column-count: 2;
      }
    }
    &_hero {
      &Background {
        padding: 7rem 0 13rem;
      }
    }
    &_copy {
      &Link {
        width: 100%;
        background-size: cover;
        background-position: center;
        height: 400px;
        position: relative;
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          opacity: 0.2;
          background: #000;
          mix-blend-mode: multiply;
        }
        @include pc {
          background-image: url('../img/company_outline_common_03.png');
        }
        @include sp {
          background-image: url('../img/company_outline_common_03_sp.png');
        }
      }
      &Image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 80rem;
        width: 100%;
        @include sp {
          max-width: 27rem;
        }
      }
    }
  }
}
