.p-movie {
  padding-bottom: 6rem;
  @include sp {
    padding-bottom: 2.5rem;
  }
  &_background {
    background: #008f3d;
    margin: -64px 0 0;
    padding-bottom: 13rem;
    @include sp {
      margin: -3.5rem 0 0;
      padding-bottom: 7rem;
    }
  }
  &_image {
    position: relative;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0.5;
      background: #000;
      mix-blend-mode: multiply;
    }
  }
  &_title {
    color: #fff;
    text-align: center;
    position: relative;
  }
  &Iframe {
    &_wrap {
      max-width: 840px;
      max-height: 450px;
      width: 77%;
      height: 33vw;
      margin: 0 auto;
      padding: 0 20px;
      @include sp {
        width: 100%;
        height: 16rem;
      }
    }
    &_item {
      width: 100%;
      height: 100%;
    }
  }
}
