.c-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &_item {
    &--half {
      @include pc {
        width: 48%;
      }
    }
  }
  &--column {
    @include sp {
      flex-direction: column;
    }
  }
  &--reverse {
    @include sp {
      flex-direction: column-reverse;
    }
  }
}
