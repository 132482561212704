@charset "UTF-8";

//フォント指定
// Noto
$noto: 'Noto Sans JP', sans-serif;
$notoSerif: 'Noto Serif JP', serif;

// 游ゴ・游明
$yumin: 游明朝, 'Yu Mincho', YuMincho, メイリオ, Meiryo, serif;
$yugo: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ',
  sans-serif;

// Oswald
$oswald: 'Oswald', sans-serif;

// Nobel
$nobel: 'Nobel-BoldCondensed', 'Yanone Kaffeesatz', sans-serif;

// green
$green: #008f3d;
