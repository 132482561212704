.p-company {
  &Business {
    &_container {
      display: flex;
    }
    &History {
      padding: 5rem 0 7rem;
      @include sp {
        padding: 3.5rem 0;
      }
      &_logo {
        // max-width: 28rem;
        // width: 100%;
        // margin: 2rem 0;
        padding-left: 7%;
        &--small {
          max-width: 22rem;
          width: 100%;
        }
      }
      &_title {
        font-size: 4rem;
        font-family: $nobel;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 1rem;
        @include sp {
          font-size: 2.8rem;
        }
      }
      &_subTitle {
        font-size: 1.6rem;
        font-weight: bold;
        color: #008f3d;
        text-align: center;
        margin-bottom: 4rem;
        @include sp {
          font-size: 1.4rem;
          margin-bottom: 1.5rem;
        }
      }
      &_nav {
        max-width: 91rem;
        width: 100%;
        margin: 0 auto 5rem;
        @include sp {
          display: block;
        }
      }
      &_list {
        @include sp {
          &:nth-child(-n + 2) {
            margin: 0 0 1rem 0;
          }
          & + & {
            margin-top: 0rem;
          }
        }
      }
      &_anchor {
        width: 20rem;
        @include sp {
          width: 14rem;
        }
      }
    }
    &About {
      padding: 7rem 0 3rem;
      @include sp {
        padding: 3.5rem 0;
      }
      &_sub {
        margin-bottom: 4rem;
        @include sp {
          margin-bottom: 2rem;
        }
      }
      &_container {
        display: flex;
        @include sp {
          display: block;
        }
      }
      &_subTitle {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        color: #008f3d;
        margin-bottom: 2rem;
        line-height: 1.5;
        @include sp {
          font-size: 1.6rem;
          margin-bottom: 1rem;
        }
      }
      &_item {
        flex: 1;
        margin: 0 8rem 0 0;
        @include sp {
          margin: 0 auto 2rem;
        }
      }
    }
    &Work {
      background-color: #fff;
      padding: 7rem 0 7rem;
      @include sp {
        padding: 3.5rem 0;
      }
      &_title {
        font-size: 4rem;
        font-family: $nobel;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 1rem;
        @include sp {
          font-size: 2.8rem;
        }
      }
      &_subTitle {
        font-size: 1.6rem;
        font-weight: bold;
        color: #008f3d;
        text-align: center;
        margin-bottom: 4rem;
        @include sp {
          font-size: 1.4rem;
          margin-bottom: 1.5rem;
        }
      }
      &_container {
        display: flex;
        align-items: flex-start;
        @include sp {
          flex-direction: column;
        }
        & + & {
          margin-top: 7rem;
          @include sp {
            margin-top: 3.5rem;
          }
        }
      }
      &_image {
        max-width: 38rem;
        width: 100%;
      }
      &_item {
        flex: 1;
        margin-left: 5rem;
        @include sp {
          margin-left: 0;
        }
      }
      &_underline {
        letter-spacing: 0.04em;
        color: #008f3d;
        font-size: 2.5rem;
        font-weight: 700;
        border-bottom: 1px solid #008f3d;
        padding: 0 0 1.5rem;
        margin: 0 0 1.5rem;
        line-height: calc(32 / 25);
        @include sp {
          font-size: 1.8rem;
          padding: 0 0 1rem;
          margin: 2rem 0 2rem;
        }
      }
    }
  }
}
