.u-position {
  &_parent {
    position: relative;
  }
  &_child {
    position: absolute;
  }
  &_center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
