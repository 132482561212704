.c-box {
  max-width: 1000px;
  padding: 2rem 4rem;
  position: relative;
  @include sp {
    padding: 2rem;
  }
  &_container {
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
    }
  }
  &_image {
    margin-bottom: 1.5rem;
    max-width: 22rem;
    width: 100%;
    @include sp {
      margin-bottom: 1rem;
      max-width: 25rem;
    }
  }
  &_caption {
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0.04em;
  }
  &_card {
    @include sp {
      margin-bottom: 1.5rem;
    }
  }
  &--green {
    @include workCard(#84c76b, #bcd99b);
  }
  &--blue {
    @include workCard(#61959f, #a6c9d0);
  }
  &--orange {
    @include workCard(#f2b149, #f5d465);
  }
}
