.p-other {
  &_background {
    background-color: #fff;
    padding: 8rem 0 10rem;
    @include sp {
      padding: 4rem 0 5rem;
    }
  }
  &_container {
    display: flex;
    @include sp {
      display: block;
    }
  }
  &_text {
    font-size: 2.2rem;
    letter-spacing: 0.04em;
    color: #fff;
    font-weight: bold;
    position: relative;
    z-index: 1;
    padding-bottom: 2rem;
    display: block;
    @include sp {
      font-size: 1.8rem;
    }
  }
  &_title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #008f3d;
    letter-spacing: 0.04em;
    text-align: center;
    margin-bottom: 2rem;
    @include sp {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
  &_item {
    width: 100%;
    position: relative;
    text-align: center;
    background-size: cover;
    padding: 6%;
    @include sp {
      padding: 20% 6%;
    }
    &--recruit {
      background-image: url('../img/other_background_recruit.png');
      @include sp {
        background-image: url('../img/other_background_recruit_sp.png');
      }
    }
    &--company {
      background-image: url('../img/other_background_company.png');
      @include sp {
        background-image: url('../img/other_background_company_sp.png');
      }
    }
    &--work {
      background-image: url('../img/other_background_work.png');
      @include sp {
        background-image: url('../img/other_background_work_sp.png');
      }
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0.5;
      background: #000;
      mix-blend-mode: multiply;
      transition: transform 0.1s ease;
    }
    @include pc {
      &:hover {
        & .p-other_button {
          color: #373737;
          background-color: #fff;
          &::after {
            border-bottom: 1px solid #000;
            border-right: 2px solid #000;
          }
        }
      }
    }
  }
  &_button {
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    margin: 0 auto;
    font-family: $oswald;
    text-align: center;
    position: relative;
    display: block;
    padding: 1.5rem;
    border: 1px solid#fff;
    background-color: transparent;
    color: #fff;
    max-width: 19rem;
    width: 100%;
    @include pc {
      transition: background-color 0.3s ease, color 0.3s ease;
    }
    @include sp {
      padding: 1rem;
    }
    &::after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 0.8rem;
      border-bottom: 1px solid #fff;
      border-right: 2px solid #fff;
      transform: translateY(-50%) skew(45deg);
      right: 10%;
      top: 50%;
      @include sp {
        width: 1.5rem;
        height: 0.4rem;
      }
    }
  }
}
