.c-more {
  &Top {
    &_title {
      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: 0.04em;
      color: #008f3d;
      margin-bottom: 2rem;
      @include sp {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
    }
    &_text {
      font-size: 1.6rem;
      letter-spacing: 0.04em;
      line-height: 2;
      margin-bottom: 3rem;
      @include sp {
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
      }
    }
    &_image {
      margin-bottom: 3rem;
      @include sp {
        margin-bottom: 1.5rem;
      }
    }
  }
  &Sub {
    &_careerplan {
      background-image: url('../img/work_more_careerplan.png');
    }
    &_interview {
      background-image: url('../img/work_more_interview.png');
    }
    &_women {
      background-image: url('../img/work_more_women.png');
    }
    &_job {
      background-image: url('../img/work_more_job.png');
    }
    &_business {
      background-image: url('../img/company_more_business.png');
    }
    &_outline {
      background-image: url('../img/company_more_outline.png');
    }
    &_benefit {
      background-image: url('../img/recruit_more_benefit.png');
    }
    &_guideline {
      background-image: url('../img/recruit_more_guideline.png');
    }
    &_news {
      background-image: url('../img/recruit_more_news.png');
    }
    &_message {
      background-image: url('../img/recruit_more_message.png');
    }
    &_text {
      font-size: 2rem;
      letter-spacing: 0.04em;
      color: #fff;
      font-weight: bold;
      position: relative;
      z-index: 1;
      padding-bottom: 2rem;
      display: block;
      text-align: center;
      @include sp {
        font-size: 1.8rem;
      }
    }
    &_link {
      position: relative;
      padding: 20% 6%;
      background-repeat: no-repeat;
      background-size: cover;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0.5;
        background: #000;
        mix-blend-mode: multiply;
        transition: transform 0.1s ease;
      }
      @include pc {
        &:hover {
          & .c-moreSub_button {
            color: #373737;
            background-color: #fff;
            &::after {
              border-bottom: 1px solid #000;
              border-right: 2px solid #000;
            }
          }
        }
      }
    }
    &_button {
      font-size: 1.4rem;
      letter-spacing: 0.1em;
      margin: 0 auto;
      font-family: $oswald;
      text-align: center;
      position: relative;
      display: block;
      padding: 0.9rem;
      border: 1px solid#fff;
      background-color: transparent;
      color: #fff;
      max-width: 19rem;
      width: 100%;
      @include pc {
        transition: background-color 0.3s ease, color 0.3s ease;
      }
      @include sp {
        padding: 1rem;
      }
      &::after {
        content: '';
        position: absolute;
        width: 3rem;
        height: 0.8rem;
        border-bottom: 1px solid #fff;
        border-right: 2px solid #fff;
        transform: translateY(-50%) skew(45deg);
        right: 10%;
        top: 50%;
        @include sp {
          width: 1.5rem;
          height: 0.4rem;
        }
      }
    }
  }
}
