.c-section {
  &_container {
    display: flex;
    max-width: 1366px;
    justify-content: space-between;
    margin: 0 auto;
    @include sp {
      display: block;
    }
    &--narrow {
      max-width: 1140px;
      padding: 0 20px;
    }
  }
  &_background {
    background-color: #008f3d;
    position: relative;
    // @include sectionImage {
    //   background: url('../img/work_career_common_01.png') #008f3d no-repeat
    //     right/ contain;
    // }
    &--yellowgreen {
      background-color: #d4eccc;
    }
    &--lightblue {
      background-color: #cae7ec;
    }
    &--blue {
      background-color: #c0d4d8;
    }
    &--orange {
      background-color: #f7e5be;
    }
    &--brown {
      background-color: #dbd4c3;
    }
  }
  &_title {
    font-size: 3rem;
    color: #fff;
    font-weight: bold;
    position: relative;
    margin: 0 0 2rem;
    text-indent: 2rem;
    &--black {
      color: #373737;
    }
    @include sectionTitle {
      font-size: 2vw;
    }
    @include sp {
      width: 90%;
      margin: 0 auto 1.6rem;
      font-size: 1.8rem;
      text-indent: 1.5rem;
    }
    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 40px;
      background-color: #fff;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @include sp {
        width: 6px;
        height: 28px;
      }
    }
    &--green {
      &::before {
        background-color: #84c76b;
      }
    }
    &--lightblue {
      &::before {
        background-color: #61959f;
      }
    }
    &--blue {
      &::before {
        background-color: #61959f;
      }
    }
    &--orange {
      &::before {
        background-color: #f2b149;
      }
    }
    &--brown {
      &::before {
        background-color: #a59569;
      }
    }
  }
  &_text {
    color: #fff;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.04em;
    @include sp {
      font-size: 1.4rem;
      width: 90%;
      margin: 0 auto;
    }
    &--black {
      color: #373737;
    }
  }
  &_item {
    padding: 5rem 4% 10.2rem 10%;
    @include sp {
      padding: 2.5rem 0 5rem 0;
    }
    &--career {
      padding: 4.1rem 4% 4.1rem 10%;
      @include sp {
        padding: 2.5rem 0;
      }
    }
    &--job {
      padding: 5rem 4% 10.2rem 0;
    }
  }
  &_box {
    max-width: 57rem;
    width: 100%;
  }
  &_empty {
    width: 61rem;
    @include sp {
      width: 0;
      display: none;
    }
  }
  &_image {
    width: 100%;
    max-width: 61rem;
    &--job {
      max-width: 46rem;
      padding: 5rem 0 10.2rem;
    }
  }
}
