@for $i from 0 through 20 {
  .u-mt-#{$i} {
    margin-top: #{$i}rem;
    @include sp {
      margin-top: calc(#{$i}rem/ 2);
    }
  }
  .u-mb-#{$i} {
    margin-bottom: #{$i}rem;
    @include sp {
      margin-bottom: calc(#{$i}rem/ 2);
    }
  }
  .u-mr-#{$i} {
    margin-right: #{$i}rem;
    @include sp {
      margin-right: calc(#{$i}rem/ 2);
    }
  }
  .u-ml-#{$i} {
    margin-left: #{$i}rem;
    @include sp {
      margin-left: calc(#{$i}rem/ 2);
    }
  }
}

.u-m {
  &_right {
    margin-left: auto;
  }
  &_center {
    margin: 0 auto;
  }
}

.u-mt-2--sp {
  @include sp {
    margin-top: 2rem;
  }
}

.u-mb-1--sp {
  @include sp {
    margin-bottom: 1rem;
  }
}

.u-mb-3--sp {
  @include sp {
    margin-bottom: 3rem;
  }
}

.u-mb-5--half {
  margin-bottom: 2.5rem;
  @include sp {
    margin-bottom: calc(2.5rem / 2);
  }
}
