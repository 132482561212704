.p-recruit {
  &Message {
    &_card {
      background-color: #fff;
      max-width: 62rem;
      width: 100%;
      padding: 4rem;
      z-index: 3;
      filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.16));
      @include header {
        padding: 3vw;
      }
      @include sp {
        padding: 2rem;
      }
      &--left {
        @include pc {
          margin-left: -50px;
        }
      }
      &--right {
        @include pc {
          margin-right: -50px;
        }
      }
      &Title {
        font-size: 3.5rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        color: #008f3d;
        margin-bottom: 2rem;
        @include header {
          font-size: 3.2vw;
          margin-bottom: 2vw;
        }
        @include sp {
          font-size: 2.4rem;
          margin-bottom: 1rem;
        }
      }
      &SubTitle {
        font-size: 2.5rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 3rem;
        @include header {
          font-size: 2.3vw;
          margin-bottom: 3vw;
        }
        @include sp {
          font-size: 1.8rem;
          margin-bottom: 1.5rem;
        }
      }
      &Text {
        font-size: 1.6rem;
        letter-spacing: 0.04em;
        line-height: 2;
        @include header {
          font-size: 1.6vw;
        }
        @include sp {
          font-size: 1.4rem;
        }
      }
    }
    &_hero {
      padding: 7rem 0 12rem;
    }
    &About {
      padding: 7rem 0 4rem;
      text-align: center;
      @include sp {
        padding: 3.5rem 0 0;
        text-align: left;
      }
    }
    &_left,
    &_right {
      max-width: 52rem;
      width: 100%;
      margin-top: 50px;
      @include sp {
        margin-top: 25px;
      }
    }
    &Ability {
      padding: 4rem 0 9rem;
      @include sp {
        padding: 3.5rem 0;
      }
      &_title {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: 0.1em;
        color: #008f3d;
        margin-bottom: 2rem;
        text-align: center;
        @include sp {
          font-size: 2.1rem;
          margin-bottom: 1rem;
        }
      }
      &_container {
        display: flex;
        align-items: center;
        @include sp {
          flex-direction: column;
        }
        &--reverse {
          @include sp {
            flex-direction: column-reverse;
          }
        }
        & + & {
          margin-top: 10rem;
          @include sp {
            margin-top: 2rem;
          }
        }
      }
      &_image {
        max-width: 67rem;
        margin: 0 auto 12rem;
        @include sp {
          margin: 0 auto 2rem;
        }
      }
    }
    &Entry {
      padding: 2rem 0 2rem;
      margin-top: 3rem;
      &_title {
        font-size: 2.8rem;
        letter-spacing: 0.1em;
        color: #008f3d;
        text-align: center;
        font-weight: bold;
        margin-bottom: 4rem;
        position: relative;
        @include sp {
          margin-bottom: 2rem;
          font-size: 2rem;
          line-height: 1.5;
        }
      }
      &_text {
        max-width: 80rem;
        margin: 0 auto 4rem;
        font-size: 1.6rem;
        letter-spacing: 0.04em;
        line-height: 2;
        text-align: center;
        position: relative;
        @include sp {
          font-size: 1.4rem;
          margin: 0 auto 2rem;
        }
      }
      &_button {
        margin: 0 auto;
      }
      &_image {
        margin-bottom: 4rem;
        position: relative;
        @include sp {
          margin-bottom: 2rem;
        }
      }
      &_left {
        max-width: 1140px;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;
        @include sp {
          padding: 0;
        }
        &::before {
          content: none !important;
        }
      }
      &_background {
        position: relative;
        &::before {
          position: absolute;
          content: '';
          top: -20px;
          width: 9999px;
          height: 105%;
          background-color: #d7ebc0;
          right: 19%;
          @include sp {
            top: -10px;
            height: 103%;
            right: 0%;
            width: 100%;
          }
        }
      }
    }
  }
}
