.c-hero {
  &_container {
    display: flex;
    @include sp {
      display: block;
    }
  }
  &_background {
    position: relative;
    width: 100%;
    padding: 7rem 0 5rem;
    background-color: #008f3d;
    @include pc {
      margin-top: 80px;
    }
    @include sp {
      padding: 3.5rem 0 4.5rem;
    }
    &--large {
      // background ver.
      // width: 100%;
      // height: 520px;
      // position: relative;
      @include pc {
        padding: 9.5rem 0 7rem;
        margin-top: 150px;
      }
      @include sp {
        padding: 0 0 4.5rem;
      }
    }
  }
  &_title {
    font-size: 65px;
    font-weight: bold;
    color: #fff;
    font-family: $nobel;
    letter-spacing: 0.1em;
    margin-bottom: 1rem;
    @include header {
      font-size: 4.5rem;
    }
    @include sp {
      font-size: 3.5rem;
    }
    &--sub {
      @include pc {
        max-width: 45rem;
      }
    }
    &--large {
      font-size: 10rem;
      @include sp {
        font-size: 3.5rem;
      }
    }
  }
  &_subTitle {
    color: #fff;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    margin-bottom: 4rem;
    font-weight: bold;
    @include header {
      font-size: 1.2rem;
      margin-bottom: 3rem;
    }
    @include sp {
      margin-bottom: 2rem;
    }
    &--sub {
      @include pc {
        max-width: 45rem;
      }
    }
    &--large {
      font-size: 2rem;
    }
  }
  &_image {
    position: absolute;
    max-width: 80rem;
    width: 53%;
    top: 70px;
    right: 0;
    @include header {
      top: 60px;
    }
    &--large {
      max-width: 104rem;
      width: 100%;
      top: 0;
      @include header {
        top: 0;
      }
    }
  }
  &_empty {
    width: 90rem;
    @include sp {
      width: 0;
      display: none;
    }
  }
  &_text {
    font-size: 1.8rem;
    color: #fff;
    line-height: 2;
    letter-spacing: 0.08em;
    @include header {
      font-size: 1.4rem;
    }
    @include sp {
      font-size: 1.4rem;
    }
    &--sub {
      @include pc {
        max-width: 45rem;
      }
    }
  }
  &_copy {
    & + & {
      margin-top: 2rem;
      @include sp {
        margin-top: 1rem;
      }
    }
    &Text {
      font-weight: bold;
      background-color: #373737;
      padding: 1rem 2%;
      font-size: 2.8rem;
      color: #fff;
      display: inline-block;
      letter-spacing: 0.1rem;
      // background ver.
      // display: block;
      // width: max-content;
      @include sp {
        font-size: 1.6em;
      }
    }
  }
  &_breadcrumb {
    display: flex;
    font-size: 1.2rem;
    color: #fff;
    text-align: right;
    position: absolute;
    bottom: 5%;
    right: 2%;
    &--black {
      justify-content: flex-end;
      position: inherit;
      bottom: auto;
      right: auto;
      color: #373737;
    }
  }
  &_item {
    @include sp {
      padding: 3.5rem 0 4.5rem;
    }
    &--large {
      position: relative;
      z-index: 3;
      padding: 0 0 7rem;
      // background ver.
      // left: 10%;
      @include sp {
        padding: 3.5rem 0 3.5rem;
      }
    }
  }
}
