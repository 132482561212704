@import 'company/business';
@import 'company/outline';
@import 'company/more';

.p-company {
  padding-bottom: 12rem;
  @include sp {
    padding-bottom: 4rem;
  }
  &_hero {
    top: 70px;
  }
  &_greet {
    padding: 6rem 0 3rem;
    @include sp {
      padding: 3.5rem 0;
    }
    &Container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include sp {
        flex-direction: column-reverse;
      }
    }
    &Title {
      font-size: 2.2rem;
      line-height: 2;
      font-weight: bold;
      letter-spacing: 0.04em;
      color: #008f3d;
      margin-bottom: 3rem;
      @include sp {
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
    }
    &Item {
      max-width: 62rem;
      width: 100%;
      margin: 0 8% 0 0;
      flex: 1;
      @include sp {
        margin: 0rem;
      }
    }
    &Image {
      position: relative;
      max-width: 31rem;
      width: 100%;
      @include sp {
        margin: 0 auto 2rem;
        width: 50%;
      }
      img {
        position: relative;
      }
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -15%;
        width: 150%;
        height: 70%;
        background-color: #d7ebc0;
        @include sp {
          left: -30%;
          width: 9999px;
        }
      }
    }
  }
  &_autograph {
    display: flex;
    align-items: center;
    &Text {
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: 0.04em;
      @include sp {
        font-size: 1.2rem;
      }
    }
    &Image {
      max-width: 18rem;
      width: 100%;
      @include sp {
        max-width: 10rem;
      }
    }
  }
  &_text {
    font-size: 1.6rem;
    letter-spacing: 0.04em;
    line-height: 2;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_strong {
    color: #008f3d;
    font-size: 1em;
    font-weight: bold;
  }
}
