html {
  font-size: 62.5%;
  @include sp {
    font-size: calc(100vw / 32);
  }
}

body {
  font-family: $noto;
  color: #373737;
}

main {
  overflow: hidden;
  background-color: #f2f1ea;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: 'メイリオ', Meiryo;
  }
}

img {
  width: 100%;
  display: block;
  height: auto;
}

.pc {
  @include pc {
    display: block !important;
  }

  @include sp {
    display: none !important;
  }
}

.pc--flex {
  @include pc {
    display: flex !important;
  }

  @include sp {
    display: none !important;
  }
}

.sp {
  @include pc {
    display: none !important;
  }

  @include sp {
    display: block !important;
  }
}

.sp--flex {
  @include pc {
    display: none !important;
  }

  @include sp {
    display: flex !important;
  }
}

.sectionImage {
  @include sectionImage {
    display: none;
  }
  @include sectionTitle {
    display: block;
  }
  @include sp {
    display: none;
  }
}

.header {
  @include pc {
    display: none;
  }
  @include header {
    display: block;
  }
  @include sp {
    display: none;
  }
}

// 追加
*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
}
