.c-menu {
  &_container {
    display: flex;
    &--inner {
      flex: 1;
      padding: 0 0 0 7rem;
    }
  }
  &_list {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    height: 70px;
    @include header {
      height: 60px;
    }
    &.is-current {
      background: #008f3d;
      color: #fff;
    }
    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #008f3d transparent transparent transparent;
      left: 50%;
      bottom: -10px;
      transform: translateX(-50%);
      opacity: 0;
      z-index: 3;
    }
    &:hover {
      background: #008f3d;
      color: #fff;
    }
  }
  &_text {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 2rem;
    line-height: 1.3;
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 40px;
      background-color: #373737;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
  &_svg {
    max-width: 16rem;
    width: 100%;
    @include header {
      max-width: 12rem;
    }
    &--work {
      max-width: 10rem;
      width: 100%;
      @include header {
        max-width: 8rem;
      }
    }
    &--recruit {
      max-width: 22rem;
      width: 100%;
      @include header {
        max-width: 16rem;
      }
    }
  }
  &Inner {
    &_container {
      position: fixed;
      left: 0;
      top: 70px;
      @include header {
        top: 60px;
      }
      text-align: center;
      width: 100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s opacity ease, background-color 0.3s ease;
      pointer-events: none;
      display: flex;
      background-color: #f2f1ea;
    }
    &_list {
      padding: 3.5rem 2rem;
      cursor: pointer;
      width: 100%;
      max-width: 220px;
      @include header {
        padding: 2rem;
      }
    }
    &_title {
      background: #008f3d;
      padding: 3.5rem 4rem 3.5rem 13.5rem;
      @include header {
        padding: 2rem;
      }
    }
    &_sub {
      font-size: 1.4rem;
      color: #fff;
      font-weight: bold;
      z-index: 3;
      white-space: nowrap;
      @include header {
        font-size: 1rem;
      }
    }
    &_empty {
      width: 100%;
    }
    &_link {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      max-width: 180px;
      &--careerplan {
        &::after {
          background-image: url('../img/work_more_careerplan.png');
        }
      }
      &--interview {
        &::after {
          background-image: url('../img/work_more_interview.png');
        }
      }
      &--women {
        &::after {
          background-image: url('../img/work_more_women.png');
        }
      }
      &--job {
        &::after {
          background-image: url('../img/work_more_job.png');
        }
      }
      &--business {
        &::after {
          background-image: url('../img/company_more_business.png');
        }
      }
      &--outline {
        &::after {
          background-image: url('../img/company_more_outline.png');
        }
      }
      &--benefit {
        &::after {
          background-image: url('../img/recruit_more_benefit.png');
        }
      }
      &--guideline {
        &::after {
          background-image: url('../img/recruit_more_guideline.png');
        }
      }
      &--news {
        &::after {
          background-image: url('../img/recruit_more_news.png');
        }
      }
      &--message {
        &::after {
          background-image: url('../img/recruit_more_message.png');
        }
      }
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include pc {
          transition: ease 0.1s;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: #000;
        mix-blend-mode: multiply;
        z-index: 3;
        @include pc {
          transition: ease 0.1s;
        }
      }
      &:hover {
        &::after,
        &::before {
          @include pc {
            transform: scale(1.1, 1.1);
          }
        }
      }
    }
    &_directory {
      font-size: 1.8rem;
      color: #fff;
      text-align: left;
      @include header {
        font-size: 1.4rem;
      }
    }
  }
}

.c-menu_list:hover .c-menuInner_container {
  opacity: 1;
  visibility: visible;
  top: 70px;
  pointer-events: auto;
  z-index: 1;
  box-shadow: 0 3px 6px 0 #b7b7b7;
  @include header {
    top: 60px;
  }
}

.c-menu_list:hover::before {
  opacity: 1;
}
