.p-work {
  &Career {
    &_hero {
      padding: 7rem 0 10rem;
      &Title {
        @include header {
          max-width: 29rem;
        }
      }
      &Text {
        @include header {
          max-width: 29rem;
        }
      }
    }
    &_person {
      @include pc {
        max-width: 19rem;
        width: 100%;
      }
    }
    &_grade {
      @include pc {
        max-width: 92rem;
        width: 100%;
      }
    }
    &_card {
      display: flex;
      background-color: #f2f1ea;
      padding: 3rem;
      position: relative;
      @include sp {
        display: block;
        padding: 2rem;
      }
      &Image {
        margin-right: 3.5rem;
        @include sp {
          margin-right: 0rem;
          margin-bottom: 2rem;
        }
      }
      &Item {
        flex: 1;
      }
      &Box {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
      }
      &Name {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        @include sp {
          font-size: 1.6rem;
        }
      }
      &Project {
        font-size: 1.4rem;
        letter-spacing: 0.04em;
        padding-left: 3rem;
        @include sp {
          font-size: 1.2rem;
          padding-left: 1rem;
        }
      }
      &Badge {
        position: absolute;
        left: -1%;
        top: -7%;
        padding: 0.8rem 2.5rem;
        color: #fff;
        font-size: 2rem;
        font-weight: bold;
        @include sp {
          font-size: 1.4rem;
          top: -3%;
          padding: 0.8rem 1rem;
        }
        &--green {
          background-color: #84c76b;
        }
        &--lightblue {
          background-color: #67bac9;
        }
        &--blue {
          background-color: #61959f;
        }
        &--orange {
          background-color: #e3aa29;
        }
        &--brown {
          background-color: #a59569;
        }
      }
    }
    &_button {
      margin: 3rem 0 0 auto;
      @include sp {
        margin: 0 auto;
      }
    }
    &_graph {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5rem;
      @include sp {
        margin-bottom: 3rem;
      }
    }
    &_about {
      text-align: center;
      @include sp {
        text-align: left;
      }
    }
    &_wrap {
      padding: 7rem 0rem;
      @include sp {
        padding: 3.5rem 0rem;
      }
    }
    &_anchor {
      width: 20rem;
      &--large {
        width: 30rem;
        @include sp {
          width: 25rem;
        }
      }
    }
    &_step {
      background-color: #fff;
      &List {
        & + & {
          margin-top: 7rem;
          @include sp {
            margin-top: 3.5rem;
          }
        }
      }
      &Wrap {
        padding: 4rem 0 8rem;
        @include sp {
          padding: 3.5rem 0;
        }
      }
    }
    &_flow {
      padding: 5rem 0rem;
      @include sp {
        padding: 3.5rem 0;
      }
      &Container {
        display: flex;
        margin-bottom: 4rem;
        @include sp {
          display: block;
          margin-bottom: 2rem;
        }
      }
      &Title {
        font-size: 2.5rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 3.5rem;
        color: #008f3d;
        @include sp {
          font-size: 2.2rem;
          margin-bottom: 1.5rem;
        }
      }
      &Item {
        flex: 1;
      }
      &Image {
        padding-left: 5rem;
        max-width: 38rem;
        width: 100%;
        @include sp {
          padding-left: 0rem;
        }
      }
    }
    &_breadcrumb {
      max-width: 110rem;
      width: 100%;
    }
    &_point {
      background-color: #d7ebc0;
      padding: 5rem 0rem;
      &Card {
        background-color: #fff;
        max-width: 80rem;
        width: 100%;
        margin: 0 auto;
        padding: 4rem;
        @include sp {
          padding: 2rem;
        }
      }
      &Title {
        font-size: 4rem;
        font-family: $nobel;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 1rem;
        @include sp {
          font-size: 2.8rem;
        }
      }
      &SubTitle {
        font-size: 1.6rem;
        font-weight: bold;
        color: #008f3d;
        text-align: center;
        margin-bottom: 4rem;
        @include sp {
          font-size: 1.4rem;
          margin-bottom: 1.5rem;
        }
      }
      &List {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem 0rem;
        border-bottom: 1px solid #d1d1d8;
        @include sp {
          align-items: flex-start;
          padding: 1rem 0rem;
        }
      }
      &Image {
        margin-right: 2rem;
        max-width: 2.9rem;
        width: 100%;
        @include sp {
          max-width: 2rem;
          margin-right: 1rem;
        }
      }
      &Text {
        font-size: 1.6rem;
        letter-spacing: 0.04em;
        flex: 1;
        line-height: 2;
        @include pc {
          margin-top: 1rem;
        }
        @include sp {
          font-size: 1.4rem;
          line-height: 2;
        }
      }
    }
    &_section {
      &--evaluation {
        padding: 2.8rem 4% 2.8rem 10%;
        @include sp {
          padding: 2.5rem 0;
        }
      }
    }
    &_single {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 2rem;
      @include sp {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }
      &--green {
        color: #84c76b;
        &::after {
          content: '';
          margin-left: 2rem;
          height: 1px;
          flex: 1;
          background-color: #84c76b;
          @include sp {
            margin-left: 1rem;
          }
        }
      }
      &--lightblue {
        color: #67bac9;
        &::after {
          content: '';
          margin-left: 2rem;
          height: 1px;
          flex: 1;
          background-color: #67bac9;
          @include sp {
            margin-left: 1rem;
          }
        }
      }
      &--blue {
        color: #61959f;
        &::after {
          content: '';
          margin-left: 2rem;
          height: 1px;
          flex: 1;
          background-color: #61959f;
          @include sp {
            margin-left: 1rem;
          }
        }
      }
      &--orange {
        color: #e3aa29;
        &::after {
          content: '';
          margin-left: 2rem;
          height: 1px;
          flex: 1;
          background-color: #e3aa29;
          @include sp {
            margin-left: 1rem;
          }
        }
      }
      &--brown {
        color: #a59569;
        &::after {
          content: '';
          margin-left: 2rem;
          height: 1px;
          flex: 1;
          background-color: #a59569;
          @include sp {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}
