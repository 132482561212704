.c-schedule {
  background-color: #fff;
  box-shadow: 0 3px 6px 0 #b7b7b7;
  padding: 3rem;
  margin: -7rem 0 5rem;
  @include sp {
    padding: 1.5rem;
    margin: -3rem 0 5rem;
  }
  &_title {
    font-size: 4rem;
    font-weight: bold;
    font-family: $nobel;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 1rem;
    @include sp {
      font-size: 3rem;
    }
  }
  &_subTitle {
    font-size: 1.6rem;
    font-weight: bold;
    color: #008f3d;
    text-align: center;
    margin-bottom: 5rem;
    @include sp {
      font-size: 1.4rem;
      margin-bottom: 2.5rem;
    }
    &--yellowgreen {
      color: #84c76b;
    }
    &--lightblue {
      color: #67bac9;
    }
    &--blue {
      color: #61959f;
    }
    &--orange {
      color: #f2b149;
    }
    &--brown {
      color: #a59569;
    }
  }
  &_container {
    display: flex;
    @include sp {
      display: block;
    }
  }
  &_time {
    font-size: 1.4rem;
    color: $green;
    letter-spacing: 0.04em;
    margin-bottom: 2rem;
    position: relative;
    padding-left: 1rem;
    text-indent: 4rem;
    @include sp {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 4rem;
      height: 0.1rem;
      background-color: $green;
    }
    &--yellowgreen {
      color: #84c76b;
      &::before {
        background-color: #84c76b;
      }
    }
    &--lightblue {
      color: #67bac9;
      &::before {
        background-color: #67bac9;
      }
    }
    &--blue {
      color: #61959f;
      &::before {
        background-color: #61959f;
      }
    }
    &--orange {
      color: #f2b149;
      &::before {
        background-color: #f2b149;
      }
    }
    &--brown {
      color: #a59569;
      &::before {
        background-color: #a59569;
      }
    }
  }
  &_topic {
    font-size: 1.8rem;
    color: $green;
    font-weight: bold;
    letter-spacing: 0.04em;
    margin-bottom: 2rem;
    @include sp {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
    &--yellowgreen {
      color: #84c76b;
    }
    &--lightblue {
      color: #67bac9;
    }
    &--blue {
      color: #61959f;
    }
    &--orange {
      color: #f2b149;
    }
    &--brown {
      color: #a59569;
    }
  }
  &_text {
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.04em;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_image {
    max-width: 40rem;
    width: 100%;
    @include sp {
      margin-bottom: 2rem;
    }
  }
  &_item {
    flex: 1;
    @include pc {
      margin-left: 4rem;
    }
  }
  &_list {
    & + & {
      margin-top: 4rem;
    }
  }
}
