.c-nav {
  &_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include sp {
      display: block;
    }
    &--large {
      max-width: 62rem;
      margin: 0 auto;
    }
    &--short {
      max-width: 88rem;
      margin: 0 auto;
    }
    &--center {
      justify-content: center;
      gap: 0 2rem;
    }
  }
  &_list {
    width: 100%;
    max-width: 20rem;
    &--large {
      max-width: 30rem;
      @include sp {
        max-width: 20rem;
      }
    }
    @include sp {
      margin: 0 auto;
      & + & {
        margin: 1rem auto 0;
      }
    }
  }
  &_item {
    background-color: transparent;
    font-size: 1.6rem;
    font-weight: bold;
    color: #008f3d;
    border: #008f3d 1px solid;
    padding: 2rem 0rem;
    text-align: center;
    position: relative;
    @include pc {
      transition: color 0.3s, background-color 0.3s;
    }
    @include sp {
      margin: 0 auto;
      font-size: 1.4rem;
      padding: 1rem;
      & + & {
        margin-top: 1rem;
      }
    }
    &--long {
      @include sp {
        font-size: 1.2rem;
        text-align: left;
      }
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 45%;
      right: 4%;
      width: 1.2rem;
      height: 1.2rem;
      border-top: 1px solid #008f3d;
      border-right: 1px solid #008f3d;
      transform: translate(-50%, -50%) rotate(135deg);
      @include pc {
        transition: border-top 0.3s, border-right 0.3s;
      }
      @include sp {
        right: 3%;
        width: 1rem;
        height: 1rem;
      }
    }
    &:hover {
      @include pc {
        color: #fff;
        background-color: #008f3d;
        &::after {
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
        }
      }
    }
  }
}
