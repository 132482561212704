@for $i from 0 through 20 {
  .u-pt-#{$i} {
    padding-top: #{$i}rem;
    @include sp {
      padding-top: calc(#{$i}rem/ 2);
    }
  }
  .u-pb-#{$i} {
    padding-bottom: #{$i}rem;
    @include sp {
      padding-bottom: calc(#{$i}rem/ 2);
    }
  }
  .u-pr-#{$i} {
    padding-right: #{$i}rem;
    @include sp {
      padding-right: calc(#{$i}rem/ 2);
    }
  }
  .u-pl-#{$i} {
    padding-left: #{$i}rem;
    @include sp {
      padding-left: calc(#{$i}rem/ 2);
    }
  }
}
