.l-wrap {
  width: 100%;
  max-width: 1140px; // 各デザイン毎に変更
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  @include sp {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
  &--contact {
    width: 100%;
    max-width: 840px; // 各デザイン毎に変更
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    @include sp {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
  }
}
