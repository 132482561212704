.c-hamburger {
  &_label {
    display: flex;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    z-index: 90;
    background: #008f3d;
  }
  &_button {
    display: block;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background: #ffffff;
    position: absolute;
    transition: 0.5s ease;
    &.is-active {
      background-color: rgba(255, 255, 255, 0);
      &::before {
        bottom: 0;
        transform: rotate(45deg);
      }
      &::after {
        top: 0;
        transform: rotate(315deg);
      }
    }
  }
  &_button::before,
  &_button::after {
    content: '';
    display: block;
    height: 3px;
    width: 25px;
    border-radius: 3px;
    background: #ffffff;
    position: absolute;
    transition: 0.5s ease;
  }
  &_button::before {
    bottom: 8px;
  }
  &_button:after {
    top: 8px;
  }
  &_box {
    width: 100%;
    position: fixed;
    left: 0;
    top: 50px;
    height: 0;
    z-index: 5;
    background: none;
    transition: ease 0.5s;
    visibility: hidden;
    overflow: hidden;
    &.is-open {
      height: 100%;
      visibility: visible;
    }
    &::after {
      // position: absolute;
      // content: '';
      // height: 100%;
      // width: 100%;
      // background-color: #000;
      // z-index: 15;
      // opacity: 0.65;
    }
  }
  &_list {
    border-top: solid 1px #646464;
  }
  &Inner {
    &_directory {
      font-size: 1.6rem;
      font-weight: bold;
      color: #008f3d;
      letter-spacing: 0.04em;
      text-align: center;
      display: block;
      background-color: #f2f1ea;
      padding: 2rem 0rem;
      position: relative;
      @include sp {
        transition: color 0.3s, background-color 0.3s;
      }
      &.is-active {
        color: #ffffff;
        background-color: #373737;
        &::after {
          border-top: #ffffff 2px solid;
          border-right: #ffffff 2px solid;
          transform: rotate(315deg);
          bottom: -15%;
        }
      }
      &::after {
        content: '';
        display: block;
        width: 1em;
        height: 1em;
        border-top: #008f3d 2px solid;
        border-right: #008f3d 2px solid;
        transform: rotate(135deg);
        position: absolute;
        right: 10%;
        top: 0;
        bottom: 15%;
        margin: auto;
        transition: transform 0.3s;
      }
    }
    &_sub {
      font-size: 1.4rem;
      letter-spacing: 0.04em;
      text-align: center;
      color: #ffffff;
      padding: 2rem 0rem;
    }
    &_list {
      &:nth-child(n + 2) {
        border-top: solid 1px #ffffff;
      }
    }
    &_box {
      width: 100%;
      left: 0;
      opacity: 0;
      height: 0;
      z-index: 5;
      background: #008f3d;
      overflow: hidden;

      & * {
        max-height: 0;
        padding: 0;
        opacity: 0;
        transition: max-height 0.3s ease, padding 0.3s ease, opacity 0.3s ease;
      }

      &.is-open {
        height: 100%;
        opacity: 1;

        & * {
          max-height: 100%;
          opacity: 1;
        }

        .c-hamburgerInner_sub {
          padding: 2rem 0;
        }
      }
    }
  }
  &_check {
    display: none;
  }
  &_background {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background-color: #000;
    z-index: 15;
    opacity: 0.65;
  }
}

// #hamburger-check:checked ~ .c-hamburger_label.is-active .c-hamburger_button {
//   background-color: rgba(255, 255, 255, 0);
// }
// #hamburger-check:checked
//   ~ .c-hamburger_label.is-active
//   .c-hamburger_button::before {
//   bottom: 0;
//   transform: rotate(45deg);
// }
// #hamburger-check:checked
//   ~ .c-hamburger_label.is-active
//   .c-hamburger_button::after {
//   top: 0;
//   transform: rotate(315deg);
// }
