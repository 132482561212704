.p-recruit {
  &Benefit {
    &_image {
      max-width: 38rem;
      width: 100%;
    }
    &_container {
      display: flex;
      align-items: flex-start;
      @include sp {
        display: block;
      }
    }
    &_item {
      @include pc {
        margin-left: 5rem;
      }
    }
    &_hero {
      padding: 7rem 0 12rem;
    }
    &About {
      padding: 7rem 0;
      text-align: center;
      @include sp {
        padding: 3.5rem 0;
        text-align: left;
      }
    }
    &Training {
      background-color: #fff;
      &_wrap {
        padding: 5rem 0 8rem;
        @include sp {
          padding: 3.5rem 0;
        }
      }
      &_title {
        letter-spacing: 0.04em;
        color: #008f3d;
        font-size: 2.5rem;
        font-weight: bold;
        border-bottom: 1px solid #008f3d;
        padding: 0 0 2rem;
        margin: 0 0 2rem;
        @include sp {
          font-size: 2rem;
          margin: 2rem 0;
          padding: 0 0 1rem;
        }
      }
      &_list {
        & + & {
          margin-top: 3rem;
          @include sp {
            margin-top: 2rem;
          }
        }
      }
      &_term {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        color: #008f3d;
        margin: 0 0 1rem;
        position: relative;
        text-indent: 2rem;
        &::before {
          content: '';
          position: absolute;
          width: 14px;
          height: 14px;
          background: #008f3d;
          top: 50%;
          left: 5px;
          transform: translate(-50%, -50%);
          @include sp {
            width: 10px;
            height: 10px;
          }
        }
        @include sp {
          font-size: 1.6rem;
          text-indent: 1.6rem;
        }
        &--small {
          font-size: 1.6rem;
          text-indent: 0rem;
          &::before {
            content: none;
          }
          @include sp {
            font-size: 1.4rem;
            text-indent: 0rem;
          }
        }
      }
      &_description {
        font-size: 1.6rem;
        letter-spacing: 0.04em;
        line-height: 2;
        @include sp {
          font-size: 1.4rem;
        }
        &--small {
          margin: 0 0 2rem;
        }
        &--margin {
          margin-bottom: 3rem;
          @include sp {
            margin-bottom: 2rem;
          }
        }
      }
    }
    &Welfare {
      background-color: #fff;
      &_wrap {
        padding: 5rem 0 8rem;
        @include sp {
          padding: 3.5rem 0;
        }
      }
      &_list {
        max-width: 91rem;
        margin: 0 auto;
      }
      &_term {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.04rem;
        padding: 1rem;
        color: #008f3d;
        background-color: #d7ebc0;
        margin-bottom: 1rem;
        @include sp {
          font-size: 1.6rem;
        }
      }
      &_description {
        font-size: 1.6rem;
        letter-spacing: 0.04em;
        line-height: 2;
        &:not(:last-of-type) {
          margin-bottom: 3rem;
        }
        @include sp {
          font-size: 1.4rem;
        }
      }
    }
  }
}
