.c-button {
  font-family: $oswald;
  text-align: center;
  position: relative;
  display: block;
  &::after {
    content: '';
    position: absolute;
    width: 3rem;
    height: 0.8rem;
    border-bottom: 1px solid #fff;
    border-right: 2px solid #fff;
    transform: translateY(-50%) skew(45deg);
    right: 10%;
    top: 50%;
    @include sp {
      width: 1.5rem;
      height: 0.4rem;
    }
  }
  &--top {
    font-family: $oswald;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #008f3d;
    font-size: 2rem;
    max-width: 30rem;
    padding: 2rem;
    border: 1px solid transparent;
    @include buttonHover(#008f3d);
    @include sp {
      font-size: 1.6rem;
      max-width: 15rem;
      padding: 1rem;
    }
  }
  &--black {
    font-family: $oswald;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #373737;
    font-size: 2rem;
    max-width: 30rem;
    padding: 2rem;
    border: 1px solid transparent;
    margin: 0 0 0 auto;
    @include buttonHover(#373737);
    @include sp {
      font-size: 1.6rem;
      max-width: 15rem;
      padding: 1rem;
      margin: 0 auto;
    }
  }
  &--sub {
    font-family: $oswald;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: #008f3d;
    font-size: 1.4rem;
    max-width: 19rem;
    width: 100%;
    padding: 1.4rem;
    border: 1px solid transparent;
    @include buttonHover(#008f3d);
    @include sp {
      font-size: 1.2rem;
    }
    &:hover {
      @include pc {
        padding: 1.4rem;
      }
    }
  }
}
