.l-footer {
  background: #008f3d;
  padding: 5rem 0 1rem;
  @include sp {
    position: relative;
    padding: 2.5rem 0 5rem;
  }
  &_text {
    font-size: 1.2rem;
    color: #fff;
  }
  &_directory {
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    &:hover {
      opacity: 0.7;
    }
    @include sp {
      font-size: 1.2rem;
    }
  }
  &_list {
    color: #fff;
    font-size: 1.4rem;
    position: relative;
    display: flex;
    text-indent: 2rem;
    align-items: center;
    margin-bottom: 1.5rem;
    @include sp {
      font-size: 1rem;
    }
    &::before {
      content: '';
      position: absolute;
      border-bottom: 2px solid #fff;
      width: 15px;
    }
  }
  &_logo {
    max-width: 15rem;
    width: 100%;
    @include sp {
      max-width: 10rem;
    }
  }
  &_link {
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  &_contents {
    display: flex;
    justify-content: space-between;
    @include sp {
      display: block;
    }
  }
  &_container {
    display: flex;
    @include sp {
      display: none;
    }
  }
  &_icon {
    width: 16px;
    height: 16px;
    margin-left: 1rem;
    @include sp {
      width: 11px;
      height: 11px;
    }
  }
  &_link {
    display: flex;
    align-items: center;
  }
  &_item {
    @include sp {
      margin-bottom: 1.5rem;
    }
    & + & {
      @include pc {
        margin-left: 5rem;
      }
    }
  }
  &_address {
    line-height: 2;
    @include sp {
      margin-bottom: 1.5rem;
    }
  }
  &_copyright {
    text-align: right;
    display: block;
    font-family: Verdana, 'Droid Sans';
    @include sp {
      text-align: center;
    }
  }
  &_box {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 65535;
  }
}
