.c-line {
  margin: 0 auto;
  width: 1px;
  background-color: #000;
  display: block;
  height: 4rem;
  @include sp {
    height: 2rem;
  }
  &--white {
    background-color: #fff;
  }
}
