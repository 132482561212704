.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center;
  & .current {
    font-family: Noto Sans JP, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
      'メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'MS Pゴシック',
      'MS PGothic', sans-serif;
    font-size: 1.8rem;
    background-color: #008f3d;
    color: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    margin: 0 1rem;
    justify-content: center;
    align-items: center;
  }
  & .page {
    font-family: Noto Sans JP, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
      'メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'MS Pゴシック',
      'MS PGothic', sans-serif;
    font-size: 1.8rem;
    width: 25px;
    height: 25px;
    display: flex;
    margin: 0 1rem;
    justify-content: center;
    align-items: center;
    @include pc {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  & .previouspostslink {
    position: relative;
    color: #f2f1ea;
    &::after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 0.8rem;
      border-bottom: 1px solid #373737;
      border-left: 2px solid #373737;
      transform: translateY(-50%) skew(135deg);
      top: 50%;
      right: 0;
      transition: right 0.3s;
    }
    @include pc {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  & .nextpostslink {
    position: relative;
    color: #f2f1ea;
    &::after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 0.8rem;
      border-bottom: 1px solid #373737;
      border-right: 2px solid #373737;
      transform: translateY(-50%) skew(45deg);
      top: 50%;
      left: 0;
    }
  }
}
