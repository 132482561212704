.c-image {
  &_left {
    position: relative;
    z-index: 2;
    &::before {
      @include pc {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: #d7ebc0;
        left: -10%;
        top: 12%;
        z-index: -1;
      }
    }
  }
  &_right {
    position: relative;
    z-index: 2;
    &::before {
      @include pc {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: #d7ebc0;
        left: 10%;
        top: 12%;
        z-index: -1;
      }
    }
  }
}
