.c-timeline {
  &Inner {
    &_container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &--end {
        justify-content: flex-end;
      }
      &--2013 {
        padding-top: 6%;
      }
      &--2016 {
        justify-content: center;
        padding-top: 6%;
      }
    }
    &_item {
      position: relative;
      &--right {
        // &::after {
        //   content: '';
        //   position: absolute;
        //   top: 10px;
        //   left: -10px;
        //   width: 20px;
        //   height: 20px;
        //   background-color: #008f3d;
        //   border-radius: 10px;
        // }
      }
      &--left {
        // &::after {
        //   content: '';
        //   position: absolute;
        //   top: 10px;
        //   right: -10px;
        //   width: 20px;
        //   height: 20px;
        //   background-color: #008f3d;
        //   border-radius: 10px;
        // }
      }
    }
  }
  &_wrap {
    max-width: 91rem;
    margin: 0 auto;
    & li:last-child {
      & > .c-timeline_item {
        &::before {
          content: none;
        }
      }
    }
    & li:nth-last-child(2) {
      & > .c-timeline_item {
        &::before {
          content: none;
        }
      }
    }
  }
  &_container {
    display: flex;
  }
  &_right {
    display: flex;
    justify-content: flex-end;
  }
  &_left {
    display: flex;
    justify-content: flex-start;
  }
  &_empty {
    width: 50%;
  }
  &_text {
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    line-height: 2;
    @include sp {
      font-size: 1.2rem;
    }
    &--right {
      padding-left: 6%;
    }
    &--left {
      padding-right: 6%;
    }
    &--mark {
      text-indent: -2rem;
      padding-left: calc(6% + 2rem);
      &::before {
        content: '・';
      }
    }
  }
  &_mark {
    text-indent: -2rem;
    &::before {
      content: '・';
    }
    &--left {
      padding-left: 2rem;
    }
    &--right {
      padding-left: calc(6% + 2rem);
    }
  }
  &_image {
    max-width: 28rem;
    width: 100%;
    margin: 2rem 0 4rem;
    @include sp {
      max-width: 12rem;
    }
  }
  &_year {
    font-family: $nobel;
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #008f3d;
    display: flex;
    align-items: center;
    position: relative;
    @include sp {
      font-size: 2.4rem;
    }
    &--right {
      &::before {
        content: '';
        margin-right: 2rem;
        height: 1px;
        flex: 1;
        background-color: #bebebe;
        @include sp {
          margin-right: 1rem;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -11px;
        width: 20px;
        height: 20px;
        background-color: #008f3d;
        border-radius: 10px;
      }
    }
    &--left {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -9px;
        width: 20px;
        height: 20px;
        background-color: #008f3d;
        border-radius: 10px;
      }
      &::after {
        content: '';
        margin-left: 2rem;
        height: 1px;
        flex: 1;
        background-color: #bebebe;
        @include sp {
          margin-left: 1rem;
        }
      }
    }
  }
  &_item {
    width: 50%;
    position: relative;
    &--right {
      &::before {
        content: '';
        position: absolute;
        top: 20px;
        left: -2px;
        width: 2px;
        height: 100%;
        background-color: #008f3d;
      }
      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 20px;
      //   left: 0px;
      //   width: 20px;
      //   height: 20px;
      //   background-color: #008f3d;
      //   border-radius: 10px;
      // }
    }
    &--left {
      &::before {
        content: '';
        position: absolute;
        top: 20px;
        right: 0px;
        width: 2px;
        height: 100%;
        background-color: #008f3d;
      }
      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 20px;
      //   right: 0px;
      //   width: 20px;
      //   height: 20px;
      //   background-color: #008f3d;
      //   border-radius: 10px;
      // }
    }
  }
}
