.c-graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  &_first {
    background-color: #e6e6e6;
    flex-basis: 46%;
  }
  &_second {
    background-color: #c8c8c8;
    flex-basis: 64%;
  }
  &_third {
    background-color: #e6e6e6;
    flex-basis: 82%;
    &--green {
      background-color: #84c76b80;
    }
  }
  &_forth {
    background-color: #e6e6e6;
    flex-basis: 100%;
    &--green {
      background-color: #84c76b;
    }
  }
  &_container {
    display: flex;
    align-items: flex-end;
    max-width: 920px;
    width: 100%;
  }
  &_box {
    width: 100%;
    max-width: 23rem;
  }
  &_height {
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &_grade {
    font-size: 3.9rem;
    font-family: $nobel;
    font-weight: bold;
    color: #84c76b;
    letter-spacing: 0.1em;
    text-align: center;
  }
  &_step {
    font-size: 1.4rem;
    font-family: $nobel;
    font-weight: 500;
    color: #84c76b;
    letter-spacing: 0.1em;
    text-align: center;
  }
  &_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &_title {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 1.2;
    text-align: center;
    &--white {
      color: #fff;
    }
  }
  &_text {
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0.04em;
    &--white {
      color: #fff;
    }
  }
}
