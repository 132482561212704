.c-card {
  &_background {
    background: #fff;
    box-shadow: 0 3px 6px 0 #b7b7b7;
    @include pc {
      transition: box-shadow ease 0.1s, transform ease 0.1s;
    }
    &:hover {
      @include pc {
        box-shadow: none;
        position: relative;
        transform: translateX(10px);
      }
    }
  }
  &_item {
    @include pc {
      padding: 6% 5%;
    }
    @include sp {
      padding: 1.5rem;
    }
  }
  &_text {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.2;
    @include sp {
      font-size: 1.2rem;
    }
  }
  &_name {
    font-size: 1.8rem;
    font-weight: bold;
    @include sp {
      font-size: 1.6rem;
    }
    &--small {
      font-size: 1.4rem;
      font-weight: 500;
      display: inline-block;
      padding: 0 0 0 5%;
      width: max-content;
    }
  }
  &_list {
    max-width: 34rem;
    width: 100%;
    display: flex;
    margin: 0 2rem 6rem 0;
    @include sp {
      max-width: 28rem;
      margin: 0 auto 3rem;
    }
  }
  &_link {
    display: flex;
  }
  &_image {
    position: relative;
    @include pc {
      width: 34rem;
      object-fit: cover;
    }
    @include sp {
      width: 28rem;
      object-fit: cover;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
      background: linear-gradient(
        45deg,
        rgba(76, 114, 31, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1080px;
    margin: 0 auto;
    width: 100%;
    &--between {
      justify-content: space-between;
    }
    @include sp {
      display: block;
    }
  }
}
