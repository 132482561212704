.c-tab {
  &_wrap {
    max-width: 91rem;
    width: 100%;
    margin: 0 auto;
  }
  &_radio {
    display: none;
  }
  &_label {
    display: block;
    background-color: #bebebe;
    text-align: center;
    color: #fff;
    float: left;
    width: calc(100% / 2);
    padding: 3rem;
    font-size: 2rem;
    letter-spacing: 0.04em;
    font-weight: bold;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative;
    @include pc {
      transition: background-color 0.3s ease, color 0.3s ease;
    }
    @include sp {
      padding: 1rem;
      font-size: 1rem;
      line-height: 1.5;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100vw;
      height: 1px;
      background-color: #008f3d;
      bottom: -1px;
      left: -100%;
    }
  }
  &_box {
    display: none;
    clear: both;
    overflow: hidden;
  }
  &_container {
    padding: 5rem 0 0;
    display: flex;
    flex-wrap: wrap;
    @include sp {
      padding: 3.5rem 0 0;
    }
  }
  &_item {
    width: 100%;
  }
  &_text {
    text-align: center;
  }
  &_title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #008f3d;
    letter-spacing: 0.04em;
    margin-bottom: 3rem;
    @include sp {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
  &_small {
    font-size: 1.6rem;
    letter-spacing: 0.04em;
    color: #373737;
    font-weight: normal;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_content {
    &--newGrad {
      & + & {
        margin-top: 7rem;
      }
    }
    &--midCareer {
      & + & {
        margin-top: 9rem;
      }
    }
  }
}

#newGrad:checked ~ #newGrad_content,
#midCareer:checked ~ #midCareer_content {
  display: block;
}

#newGrad:not(:checked) + .c-tab_label:hover,
#midCareer:not(:checked) + .c-tab_label:hover {
  border: 1px solid #008f3d;
  background-color: #fff;
  color: #008f3d;
}

.c-tab .c-tab_radio:checked + .c-tab_label {
  background-color: #008f3d;
  color: #fff;
}
