.c-info {
  &_list {
    display: flex;
    border-top: solid 1px #bebebe;
    &:last-child {
      border-bottom: solid 1px #bebebe;
    }
  }
  &_date {
    padding: 2rem;
    display: flex;
    align-items: center;
    color: #008f3d;
    background-color: #f0f0f0b3;
    mix-blend-mode: multiply;
    @include sp {
      padding: 0.5rem 1rem;
    }
    &--top {
      color: #008f3d;
      background-color: #f0f0f0;
    }
  }
  &_text {
    padding: 20px 3.5%;
    transition: color 0.3s;
    @include pc {
      flex: 1;
      &:hover {
        color: #008f3d;
      }
    }
    @include sp {
      padding: 1rem 1.5rem;
    }
  }
}
