.p-recruit {
  &Guide {
    &_hero {
      padding: 7rem 0 15rem;
    }
    &Tab {
      padding: 6rem 0 3rem;
      position: relative;
      @include sp {
        padding: 3.5rem 0;
      }
    }
    &_entry {
      margin: 5rem auto 0;
    }
    &_asterisk {
      display: block;
      text-align: center;
      font-size: 1.4rem;
      letter-spacing: 0.04em;
      line-height: 1.5;
      margin: 3rem 0 0;
      @include sp {
        font-size: 1.2rem;
      }
    }
    &_column {
      padding: 2.5rem 0rem 2.5rem 2.5rem;
    }
  }
}
