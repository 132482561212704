@import 'work/interview';
@import 'work/career';
@import 'work/job';
@import 'work/more';
@import 'work/women';

.p-work {
  &_about {
    text-align: center;
    padding: 7rem 0 0rem;
    @include sp {
      text-align: left;
      padding: 4.5rem 0 0rem;
    }
  }
  &_material {
    & + & {
      padding-top: 6rem;
      @include sp {
        padding-top: 3rem;
      }
    }
  }
  &_illust {
    background-color: #fff;
    max-width: 1140px;
    padding: 7rem 2rem 2rem 2rem;
    margin: 0 auto;
    @include sp {
      padding: 1rem;
      width: 90%;
      margin: 0 auto;
      padding: 3.5rem 0 1rem;
    }
    &Background {
      background-color: #fff;
    }
  }
  &_product {
    background-color: #fff;
    padding: 7rem 0 9rem;
    @include sp {
      padding: 3.5rem 0;
    }
  }
  &_subTitle {
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 2;
    color: #008f3d;
    margin-bottom: 3rem;
    @include sp {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
  }
  &_text {
    font-size: 1.6rem;
    letter-spacing: 0.04em;
    line-height: 2;
    @include sp {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
  }
  &_strong {
    font-size: 1em;
    font-weight: bold;
    color: #008f3d;
  }
  &_logo {
    max-width: 22rem;
    width: 100%;
    margin-bottom: 2rem;
    margin-left: 2rem;
    @include pc {
      flex: 1;
    }
    @include sp {
      max-width: 11rem;
      margin-bottom: 1.6rem;
    }
  }
  &_section {
    display: flex;
    align-items: center;
    &Title {
      color: #373737;
      @include sp {
        margin: 0 0 1.6rem;
        width: auto;
      }
      &--blue {
        color: #61959f;
        &::before {
          background-color: #61959f;
        }
      }
      &--orange {
        color: #f2b149;
        &::before {
          background-color: #f2b149;
        }
      }
      &--green {
        color: #84c76b;
        &::before {
          background-color: #84c76b;
        }
      }
    }
  }
  &_single {
    color: #fff;
    margin-bottom: 2rem;
  }
  &_container {
    display: flex;
    max-width: 1366px;
    justify-content: space-between;
    margin: 0 auto;
    @include sp {
      display: block;
    }
    &--odd {
    }
    &--even {
      flex-direction: row-reverse;
    }
  }
  &_image {
    max-width: 60rem;
    width: 100%;
    @include sp {
      margin-bottom: 2rem;
    }
  }
  &_item {
    max-width: 70rem;
    width: 100%;
    @include sp {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
    &--odd {
      @include pc {
        padding-right: 10%;
        margin-left: 5rem;
      }
    }
    &--even {
      padding-left: 10%;
      margin-right: 5rem;
    }
  }
  &_box {
    &--odd {
      margin: -9rem 0 0rem auto;
      @include workBox1 {
        margin: -4rem 0 0 auto;
      }
      @include workBox2 {
        margin: -2rem 0 0 auto;
      }
      @include workBox3 {
        margin: 2rem 0 0 auto;
      }
      @include sp {
        margin: 0 0 2rem 0;
      }
    }
    &--even {
      margin: -9rem auto 0rem 0;
      @include workBox1 {
        margin: -4rem auto 0 0;
      }
      @include workBox2 {
        margin: -2rem auto 0 0;
      }
      @include workBox3 {
        margin: 2rem auto 0 0;
      }
      @include sp {
        margin: 0 0 2rem 0;
      }
    }
  }
}
