.p-work {
  &Interview {
    padding-bottom: 10rem;
    @include sp {
      padding-bottom: 4rem;
    }
    &_about {
      text-align: center;
    }
    &_hero {
      &Background {
        padding: 7rem 0 14rem;
      }
      &Text {
        @include header {
          max-width: 29rem;
        }
      }
    }
    &_wrap {
      padding: 7rem 0rem;
      @include sp {
        padding: 3.5rem 0rem;
      }
    }
    &_text {
      font-size: 1.6rem;
      letter-spacing: 0.04em;
      line-height: 2;
      @include sp {
        font-size: 1.4rem;
      }
    }
    &_child {
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &_message {
      font-size: 1.8rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #fff;
      line-height: 1.6;
      font-weight: bold;
      padding: 0 5% 12px;
      @include sp {
        font-size: 1.6rem;
        padding: 0 1.5rem 1rem;
      }
    }
    &_background {
      position: relative;
      padding: 7rem 20px 0;
      @include sp {
        padding: 3.5rem 0 0;
      }
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 9999px;
        height: 80%;
        background: #d7ebc0;
        bottom: 0;
        z-index: 0;
        right: 18%;
        bottom: -60px;
        @include sp {
          bottom: -20px;
        }
      }
      &--sub {
        &::after {
          height: 72%;
          bottom: 0%;
          @include sp {
            height: 100%;
          }
        }
      }
    }
    &_zIndex {
      position: relative;
      z-index: 1;
    }
    // インタビュー詳細
    &_qa {
      padding: 3.5rem 0 7rem;
      @include sp {
        padding: 1.75rem 0 3rem;
      }
    }
    &_container {
      display: flex;
      margin-bottom: 8rem;
      align-items: flex-start;
      @include sp {
        display: block;
        margin-bottom: 0rem;
        & + & {
          margin-top: 2rem;
        }
      }
      &--odd {
      }
      &--even {
        flex-direction: row-reverse;
      }
    }
    &_question {
      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: 0.04em;
      margin-bottom: 3rem;
      @include sp {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        line-height: 1.2;
      }
    }
    &_mark {
      font-size: 4rem;
      font-weight: bold;
      font-family: $nobel;
      color: #008f3d;
      letter-spacing: 0.1em;
      margin-right: 1.5rem;
      @include sp {
        font-size: 2.6rem;
        margin-right: 0.8rem;
      }
    }
    &_answer {
      font-size: 2rem;
      font-weight: bold;
      color: #008f3d;
      letter-spacing: 0.04em;
      margin-bottom: 2rem;
      line-height: 1.5;
      @include sp {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }
    }
    &_title {
      font-size: 4.5rem;
      font-family: $nobel;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-align: center;
      margin-bottom: 1rem;
      @include sp {
        font-size: 3.6rem;
      }
      &--white {
        color: #fff;
      }
    }
    &_subTitle {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.04em;
      text-align: center;
      margin-bottom: 4rem;
      color: #008f3d;
      @include sp {
        position: relative;
        font-size: 1.4rem;
        margin-bottom: 2rem;
      }
      &--white {
        color: #fff;
      }
    }
    &Other {
      position: relative;
      padding: 7rem 0 0;
      @include sp {
        padding: 6rem 0 0;
      }
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 9999px;
        height: 55.5%;
        background: #d7ebc0;
        bottom: -30px;
        z-index: 0;
        right: 30%;
        @include sp {
          height: 63%;
          bottom: -30px;
        }
      }
      &_link {
        @include pc {
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &_container {
        display: flex;
        flex-wrap: wrap;
        @include sp {
          display: block;
        }
      }
      &_background {
        position: relative;
        box-shadow: 0 3px 6px 0 #b7b7b7;
        @include pc {
          margin: 0 5rem 5rem 0;
        }
        @include sp {
          margin: 0 auto 4rem;
        }
        & + & {
          @include sp {
            margin-top: 2rem;
          }
        }
        img {
          width: 23rem;
          height: 23rem;
          object-fit: cover;
          @include sp {
            width: 20rem;
            height: 20rem;
            margin: 0 auto;
          }
        }
      }
      &_card {
        position: absolute;
        background-color: #fff;
        padding: 1rem;
        max-width: 15.5rem;
        width: 100%;
        right: -10%;
        bottom: -5%;
        box-shadow: 0 3px 6px 0 #b7b7b7;
        @include sp {
          max-width: 14rem;
          right: 0%;
        }
      }
      &_name {
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 1rem;
      }
      &_date {
        font-size: 1.2rem;
        letter-spacing: 0.04em;
      }
    }
    &_voice {
      padding: 4rem 0 0;
      @include sp {
        padding: 3rem 0 0;
      }
      &Container {
        display: flex;
        justify-content: space-between;
        @include sp {
          display: block;
        }
      }
      &Card {
        background-color: #fff;
        padding: 3rem;
        max-width: 53rem;
        width: 100%;
        box-shadow: 0 3px 6px 0 #b7b7b7;
        @include sp {
          padding: 1.5rem;
          & + & {
            margin-top: 2rem;
          }
        }
      }
      &Box {
        display: flex;
        @include sp {
          display: block;
        }
      }
      &Image {
        img {
          border-radius: 50%;
          object-fit: cover;
          width: 180px;
          height: 180px;
          @include sp {
            width: 160px;
            height: 160px;
            margin: 0 auto;
          }
        }
      }
    }
    &_schedule {
      padding: 5rem;
      background-color: #008f3d;
      @include sp {
        padding: 3rem 0 3rem;
      }
      &Card {
        padding: 5rem 4rem;
        background-color: #fff;
        max-width: 80rem;
        margin: 0 auto;
        box-shadow: 0 3px 6px 0 #b7b7b7;
        @include sp {
          padding: 2.5rem 1.5rem;
        }
      }
      &Title {
        font-size: 2rem;
        letter-spacing: 0.04em;
        font-weight: bold;
        color: #008f3d;
        margin-bottom: 1rem;
        @include sp {
          font-size: 1.6rem;
        }
      }
      &Container {
        display: flex;
        &:last-child {
          & > .p-workInterview_task {
            border-left: none;
            padding-bottom: 0rem;
          }
        }
      }
    }
    &_time {
      font-family: $nobel;
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      color: #008f3d;
      margin-right: 2rem;
      width: 12%;
      @include sp {
        width: 26%;
        font-size: 1.6rem;
        margin-right: 1rem;
      }
    }
    &_task {
      border-left: 1px solid #d1d1d8;
      position: relative;
      flex: 1;
      padding: 0 0 5rem 3rem;
      @include sp {
        padding: 0 0 2.5rem 1.5rem;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        width: 20px;
        height: 20px;
        background-color: #008f3d;
        border-radius: 10px;
        @include sp {
          width: 16px;
          height: 16px;
          left: -8px;
        }
      }
    }
    &_image {
      &--odd {
        margin: 0 5rem 0 0;
        max-width: 38rem;
        width: 100%;
        @include sp {
          margin: 0 0 2rem 0;
        }
      }
      &--even {
        margin: 0 0 0 5rem;
        max-width: 38rem;
        width: 100%;
        @include sp {
          margin: 0 0 2rem 0;
        }
      }
    }
    &_item {
      flex: 1;
      @include sp {
        margin-top: 1rem;
      }
      &--voice {
        margin-left: 3.5rem;
        @include sp {
          margin-left: 0rem;
        }
      }
      &--odd {
        margin-left: 5rem;
        @include sp {
          margin-left: 0rem;
        }
      }
      &--even {
        margin-right: 5rem;
        @include sp {
          margin-right: 0rem;
        }
      }
    }
    &_copy {
      @include sp {
        z-index: 1;
        left: 10%;
        position: relative;
      }
      & + & {
        margin-top: 1.5rem;
        @include sp {
          margin-top: 0.8rem;
        }
      }
      &Text {
        font-weight: bold;
        padding: 1rem 2%;
        font-size: 2.5rem;
        display: block;
        width: max-content;
        background-color: #373737;
        color: #fff;
        @include sp {
          padding: 0.5rem 2%;
          font-size: 1.4rem;
        }
      }
    }
    &Hero {
      padding-bottom: 3.5rem;
      @include sp {
        padding-bottom: 1.75rem;
      }
      &_text {
        font-size: 1.6rem;
        letter-spacing: 0.04em;
        line-height: 2;
        color: #fff;
        @include sp {
          font-size: 1.2rem;
        }
      }
      &_wrap {
        @include sp {
          width: 100%;
        }
      }
      &_image {
        max-width: 85.5rem;
        margin: 0 0 7rem auto;
        @include sp {
          margin: 0 0 10rem 0;
        }
        img {
          @include pc {
            width: 85.5rem;
            height: 39rem;
            object-fit: cover;
          }
        }
        &--2 {
          margin: 0 0 7rem auto;
        }
        &--3 {
          margin: 0 0 11.5rem auto;
        }
      }
      &_background {
        width: 100%;
        max-width: 1140px;
        position: absolute;
        top: 18%;
        z-index: 2;
        @include sp {
          width: 90%;
          top: 140px;
        }
      }
      &_title {
        font-size: 2.5rem;
        font-weight: bold;
        font-family: $nobel;
        color: #008f3d;
        letter-spacing: 0.1em;
        margin-bottom: 0.5rem;
        @include sp {
          font-size: 2rem;
          left: 10%;
          position: relative;
        }
      }
      &_card {
        margin-top: 2rem;
        background-color: #008f3d;
        padding: 3rem;
        max-width: 35rem;
        width: 100%;
        @include sp {
          padding: 2.5rem 3rem 1rem 3rem;
          max-width: 30rem;
          margin-top: 1rem;
          position: absolute;
          left: 0;
          top: 70%;
        }
      }
      &_name {
        font-size: 2rem;
        letter-spacing: 0.04em;
        font-weight: bold;
        color: #fff;
        margin-bottom: 2rem;
        width: max-content;
        @include sp {
          font-size: 1.6rem;
          margin-bottom: 1rem;
        }
        &--small {
          font-size: 1.6rem;
          padding: 0 0 0 5%;
          @include sp {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.p-workInterviewHero_image.p-workInterviewHero_image--2
  + .p-workInterviewHero_background {
  @include sp {
    top: 120px;
  }
}

.p-workInterviewHero_image.p-workInterviewHero_image--3
  + .p-workInterviewHero_background {
  @include sp {
    top: 100px;
  }
}
