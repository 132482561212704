.p-jobs {
  padding-bottom: 8rem;
  @include sp {
    padding-bottom: 2.5rem;
  }
  &_background {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      width: 9999px;
      height: 42%;
      z-index: 0;
      background-color: #cce0b1;
      top: 58%;
      left: 30%;
      @include sp {
        height: 70%;
        top: 30%;
      }
    }
  }
  &_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000;
    border-radius: 50%;
    width: 30rem;
    height: 30rem;
    text-align: center;
    color: #fff;
    box-shadow: 3px 3px 16px #b7b7b7;
    margin: 0 0 2rem;
    @include pc {
      transition: box-shadow ease 0.1s, transform ease 0.1s;
    }
    &:hover {
      @include pc {
        box-shadow: none;
        position: relative;
        transform: translateX(10px);
      }
    }
    @include sp {
      width: 20rem;
      height: 20rem;
      margin: 0 auto 2rem;
    }
    &--green {
      background: #79b761;
    }
    &--blue {
      background: #61959f;
    }
    &--orange {
      background: #f2b149;
    }
  }
  &_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
    @include sp {
      display: block;
    }
  }
  &_icon {
    width: 20%;
  }
  &_directory {
    color: #fff;
    &--en {
      font-size: 3rem;
      opacity: 0.5;
      font-family: $nobel;
      letter-spacing: 0.1em;
      @include sp {
        font-size: 2rem;
      }
    }
    &--ja {
      font-size: 1.8rem;
      @include sp {
        font-size: 1.4rem;
      }
    }
  }
}
