.p-recruit {
  &News {
    padding: 6rem 0 9rem;
    @include sp {
      padding: 3.5rem 0;
    }
    &_margin {
      margin: -7rem auto 0;
      @include sp {
        margin: -3.5rem auto 0;
      }
    }
    &_hero {
      &Background {
        padding: 7rem 0 12rem;
      }
      &Title {
        @include header {
          max-width: 29rem;
        }
      }
      &Text {
        @include header {
          max-width: 29rem;
        }
      }
    }
    &_item {
      padding: 7rem 5% 9rem 0;
    }
    &_wrap {
      padding: 8rem 0 9rem;
      @include sp {
        padding: 3.5rem 0;
      }
    }
    &_card {
      background-color: #fff;
      padding: 4rem;
      @include sp {
        padding: 2rem;
      }
    }
    &--top {
      padding: 4rem 0 8rem 0;
      background-color: #fff;
      @include sp {
        padding: 2.5rem 0rem;
      }
    }
    &_button {
      &--top {
        margin: 3rem auto 0;
      }
    }
    &_title {
      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: 0.04em;
      color: #008f3d;
      margin-bottom: 3rem;
      line-height: 1.5;
      @include sp {
        font-size: 1.8rem;
      }
    }
    &_date {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.04em;
      color: #008f3d;
      margin-bottom: 2rem;
      @include sp {
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
    }
    &_article {
      h1 {
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 2rem;
        color: #008f3d;
        text-indent: 2rem;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 30px;
          background-color: #008f3d;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          @include sp {
            width: 6px;
            height: 28px;
          }
        }
        @include sp {
          font-size: 2.2rem;
          margin-bottom: 1rem;
          text-indent: 1rem;
        }
      }
      h2 {
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 2rem;
        color: #008f3d;
        text-indent: 2rem;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 28px;
          background-color: #008f3d;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          @include sp {
            width: 6px;
            height: 26px;
          }
        }
        @include sp {
          font-size: 2rem;
          margin-bottom: 1rem;
          text-indent: 1rem;
        }
      }
      h3 {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 2rem;
        color: #008f3d;
        text-indent: 2rem;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 26px;
          background-color: #008f3d;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          @include sp {
            width: 6px;
            height: 24px;
          }
        }
        @include sp {
          font-size: 1.8rem;
          margin-bottom: 1rem;
          text-indent: 1rem;
        }
      }
      h4 {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 2rem;
        color: #008f3d;
        @include sp {
          font-size: 1.6rem;
          margin-bottom: 1rem;
          text-indent: 1rem;
        }
      }
      h5 {
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 2rem;
        color: #008f3d;
        @include sp {
          font-size: 1.4rem;
          margin-bottom: 1rem;
          text-indent: 1rem;
        }
      }
      h6 {
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 0.04em;
        margin-bottom: 2rem;
        color: #008f3d;
        @include sp {
          font-size: 1.2rem;
          margin-bottom: 1rem;
          text-indent: 1rem;
        }
      }
      p {
        font-size: 1.6rem;
        letter-spacing: 0.04em;
        line-height: 2;
        @include sp {
          font-size: 1.4rem;
        }
      }
      a {
        font-size: 1.6rem;
        letter-spacing: 0.04em;
        color: #008f3d;
        display: inline-block;
        border-bottom: 1px solid #008f3d;
        line-height: 1;
        @include pc {
          &:hover {
            opacity: 0.7;
          }
        }
        @include sp {
          font-size: 1.4rem;
        }
      }
      img {
        width: auto;
        max-width: 100%;
      }
    }
  }
}
