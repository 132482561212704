.c-subTitle {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.04em;
  @include sp {
    font-size: 1.4rem;
  }
  &--green {
    color: #008f3d;
  }
  &--white {
    color: #fff;
  }
  &--contact {
    font-size: 3.6rem;
    color: #fff;
    text-align: center;
    position: relative;
    @include sp {
      font-size: 1.6rem;
    }
  }
  &_sub {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: #008f3d;
    @include sp {
      display: block;
      text-align: left;
      font-size: 2.1rem;
      line-height: 1.4;
    }
    &--center {
      @include sp {
        display: block;
        text-align: center;
        font-size: 2.1rem;
        line-height: 1.4;
      }
    }
    &::before,
    &::after {
      content: '';
      height: 1px;
      flex: 1;
      background-color: #008f3d;
      @include sp {
        content: none;
      }
    }
    &::before {
      margin-right: 2rem;
      @include sp {
        margin-right: 1rem;
      }
    }
    &::after {
      margin-left: 2rem;
      @include sp {
        margin-left: 1rem;
      }
    }
  }
  &_single {
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    &--work {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 4rem;
      @include sp {
        font-size: 1.6rem;
        margin-bottom: 2rem;
      }
      &::after {
        content: '';
        margin-left: 2rem;
        height: 1px;
        flex: 1;
        background-color: #fff;
        @include sp {
          margin-left: 1rem;
        }
      }
    }
    &--job {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 4rem;
      @include sp {
        font-size: 1.4rem;
        margin-bottom: 2rem;
      }
      &::after {
        content: '';
        margin-left: 2rem;
        height: 1px;
        flex: 1;
        background-color: #373737;
        @include sp {
          margin-left: 1rem;
        }
      }
    }
  }
}
