.l-header {
  position: sticky;
  z-index: 65535;
  background: #fff;
  width: 100%;
  top: 0;
  @include pc {
    height: 70px;
  }
  @include header {
    height: 60px;
  }
  &_container {
    display: flex;
    justify-content: space-between;
  }
  &_nav {
    padding: 12px 0 12px 3%;
    @include sp {
      display: flex;
      align-items: center;
      padding: 0 0 0 3%;
    }
  }
  &_logo {
    max-width: 27rem;
    width: 100%;
    cursor: pointer;
    @include pc {
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.7;
      }
    }
    @include header {
      max-width: 22rem;
    }
    @include sp {
      max-width: 20rem;
    }
  }
  &_button {
    font-family: $oswald;
    font-size: 2rem;
    width: 18rem;
    padding: 2rem;
    text-align: center;
    position: relative;
    display: block;
    border: 1px solid transparent;
    letter-spacing: 0.1em;
    &::after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 0.8rem;
      border-bottom: 1px solid #fff;
      border-right: 2px solid #fff;
      transform: translateY(-50%) skew(45deg);
      right: 10%;
      top: 50%;
      @include sp {
        width: 1.5rem;
        height: 0.4rem;
      }
    }
    @include pc {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    @include header {
      font-size: 1.6rem;
      width: 13rem;
      padding: 1rem;
      &:after {
        width: 2rem;
      }
      &:hover {
        padding: 0.7rem;
      }
    }
    @include sp {
      padding: 1rem;
      font-size: 1.6rem;
    }
    &--grey {
      color: #fff;
      background-color: #bebebe;
      @include buttonHover(#bebebe);
      &:hover {
        @include pc {
          padding: 2rem;
        }
      }
    }
    &--yellow {
      color: #fff;
      background-color: #e3aa29;
      @include buttonHover(#e3aa29);
      &:hover {
        @include pc {
          padding: 2rem;
        }
      }
    }
  }
  &_link {
    text-align: center;
    position: relative;
    display: block;
    color: #008f3d;
    background-color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 1.5rem 4rem;
    width: 23rem;
    padding: 2rem;
    letter-spacing: 0.1em;
    &::after {
      content: '';
      position: absolute;
      width: 2rem;
      height: 0.8rem;
      border-bottom: 1px solid #008f3d;
      border-right: 2px solid #008f3d;
      transform: translateY(-50%) skew(45deg);
      right: 5%;
      top: 50%;
      @include header {
        width: 1.3rem;
        height: 0.5rem;
      }
      @include sp {
        width: 1.5rem;
        height: 0.4rem;
      }
    }
    &:hover {
      @include pc {
        transform: scale(1.1, 1.1);
      }
    }
    @include pc {
      transition: 0.1s ease;
    }
    @include header {
      font-size: 1.2rem;
      padding: 1rem 2rem;
      width: 18rem;
    }
    @include sp {
      padding: 1rem;
    }
  }
}
