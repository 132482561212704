.p-work {
  &More {
    padding: 5rem 0 8rem;
    @include sp {
      padding: 3.5rem 0;
    }
    &--single {
      margin-top: 4rem;
      @include sp {
        margin-top: 0rem;
      }
    }
    &_title {
      font-family: $nobel;
      font-size: 4.5rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-align: center;
      margin-bottom: 1rem;
      @include sp {
        font-size: 2.7rem;
      }
    }
    &_subTitle {
      font-size: 1.6rem;
      letter-spacing: 0.04em;
      font-weight: bold;
      color: #008f3d;
      text-align: center;
      margin-bottom: 4rem;
      @include sp {
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
      }
    }
    &_item {
      &--top {
        width: 47%;
        margin-bottom: 6rem;
        @include pc {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        @include sp {
          width: 100%;
          margin-bottom: 3rem;
        }
      }
      &--sub {
        max-width: 33rem;
        width: 100%;
        margin-bottom: 2rem;
      }
    }
    &_button {
      margin: 0 0 0 auto;
      @include sp {
        margin: 0 auto;
      }
    }
    &_container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include sp {
        display: block;
      }
    }
  }
}
