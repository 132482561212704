.c-breadcrumb {
  &_container {
    display: flex;
    justify-content: space-between;
  }
  &_item {
    color: #fff;
    font-weight: bold;
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    background-color: #bebebe;
    padding: 2rem;
    max-width: 26rem;
    width: 30vw;
    text-align: center;
    @include sp {
      font-size: 1.4rem;
      padding: 1rem;
      max-width: 8.7rem;
    }
  }
  &_active {
    @include breadcrumb(#008f3d);
  }
  &_inactive {
    @include breadcrumb(#bebebe);
  }
}
