@import 'recruit/news';
@import 'recruit/more';
@import 'recruit/message';
@import 'recruit/benefit';
@import 'recruit/guide';

.p-recruit {
  padding-bottom: 12rem;
  @include sp {
    padding-bottom: 6rem;
  }
  &_about {
    &Title {
      font-size: 2.2rem;
      font-weight: bold;
      letter-spacing: 0.04em;
      color: #008f3d;
      line-height: 2;
      margin-bottom: 3rem;
      text-align: center;
      @include sp {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
        text-align: left;
      }
    }
    &Text {
      text-align: center;
      @include sp {
        text-align: left;
      }
    }
  }
  &_wrap {
    padding: 7rem 0;
    @include sp {
      padding: 3.5rem 0;
    }
  }
  &_title {
    font-family: $nobel;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 1rem;
    @include sp {
      font-size: 3.2rem;
    }
  }
  &_subTitle {
    font-size: 1.6rem;
    letter-spacing: 0.04em;
    font-weight: bold;
    color: #008f3d;
    text-align: center;
    margin-bottom: 4rem;
    @include sp {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
    }
  }
  &_text {
    font-size: 1.6rem;
    letter-spacing: 0.04em;
    line-height: 2;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_strong {
    color: #008f3d;
    font-size: 1em;
    font-weight: bold;
  }
  &_entry {
    font-size: 3.6rem;
    font-family: $oswald;
    padding: 2rem;
    letter-spacing: 0.1em;
    max-width: 42rem;
    width: 100%;
    text-align: center;
    position: relative;
    display: block;
    border: 1px solid transparent;
    color: #fff;
    background-color: #e3aa29;
    @include buttonHover(#e3aa29);
    &::after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 0.8rem;
      border-bottom: 1px solid #fff;
      border-right: 2px solid #fff;
      transform: translateY(-50%) skew(45deg);
      right: 10%;
      top: 50%;
      @include sp {
        width: 1.5rem;
        height: 0.4rem;
      }
    }
    @include sp {
      padding: 1rem;
      font-size: 1.8rem;
      max-width: 21rem;
    }
  }
}
