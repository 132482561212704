.c-table {
  &_container {
    display: flex;
    @include sp {
      display: block;
    }
    &:last-child {
      & .c-table_column {
        @include pc {
          border-bottom: 2px solid #008f3d;
        }
      }
      & .c-table_data {
        border-bottom: 2px solid #bebebe;
        @include sp {
          border-bottom: 2px solid #008f3d;
        }
      }
    }
  }
  &_wrap {
    max-width: 91rem;
    width: 100%;
    margin: 0 auto;
  }
  &_column {
    background-color: #f0f0f0b3;
    mix-blend-mode: multiply;
    width: 18%;
    padding: 2rem 2.5rem;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 2;
    color: #008f3d;
    border-top: 2px solid #008f3d;
    @include sp {
      font-size: 1.2rem;
      padding: 1rem;
      width: 100%;
    }
  }
  &_data {
    background-color: #fff;
    padding: 2rem;
    width: 82%;
    font-size: 1.6rem;
    letter-spacing: 0.04em;
    line-height: 2;
    border-top: 2px solid #bebebe;
    @include sp {
      font-size: 1.2rem;
      padding: 1rem;
      width: 100%;
      border-top: none;
    }
    &--center {
      @include pc {
        display: flex;
        align-items: center;
      }
    }
  }
  &_mark {
    font-size: 1em;
    display: inline-block;
    text-indent: -1.5rem;
    margin-left: 1rem;
    &--bold {
      font-weight: bold;
    }
    &--asterisk {
      text-indent: 0rem;
    }
  }
  &_space {
    & + & {
      margin-top: 2rem;
    }
  }
}
