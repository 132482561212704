.p-work {
  &Job {
    &Sales {
      background-color: #d4eccc;
    }
    &Marketing {
      background-color: #cae7ec;
    }
    &Develop {
      background-color: #c0d4d8;
    }
    &Technology {
      background-color: #f7e5be;
    }
    &Manufacture {
      background-color: #dbd4c3;
    }
    &About {
      &_image {
        width: 90%;
        margin: 0 auto 7rem;
        @include sp {
          margin: 0 auto 3rem;
        }
      }
    }
    &_wrap {
      padding: 7rem 0rem;
      @include sp {
        padding: 3.5rem 0rem;
      }
    }
    &_anchor {
      width: 20rem;
    }
    &_card {
      display: flex;
      max-width: 48rem;
      width: 100%;
      @include sp {
        margin-bottom: 3rem;
      }
      @include pc {
        &:nth-last-child(n + 2) {
          margin-bottom: 4rem;
        }
      }
      &Item {
        flex: 1;
        margin: 0 0 0 4rem;
        @include sp {
          margin: 0 0 0 2rem;
        }
      }
      &Image {
        img {
          width: 22rem;
          height: 22rem;
          object-fit: cover;
          @include sp {
            width: 12rem;
            height: 12rem;
            margin: 0 auto;
          }
        }
      }
      &Small {
        padding: 0;
        @include sp {
          font-size: 1.2rem;
        }
      }
      &Name {
        line-height: 1.2;
      }
      &Text {
        @include sp {
          font-size: 1rem;
          line-height: 1.4rem;
        }
      }
      &Year {
        @include sp {
          font-size: 1.2rem;
        }
      }
    }
    &_button {
      @include sp {
        margin: 0 auto;
        padding: 0.5rem;
      }
    }
    &_indent {
      margin: 0 0 4rem;
      text-indent: -1.6rem;
      padding-left: 1.6rem;
      @include sp {
        text-indent: -1.4rem;
        padding-left: 1.4rem;
      }
    }
  }
}
